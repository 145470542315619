import { CircularProgress, Button, Dialog, MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useEffect, useState } from 'react';
import useFormData from 'src/hooks/useFormData';
import useSuperFetch from 'src/hooks/useSuperFetch';
import { styled } from '@mui/material/styles';
import useComponentRepository from 'src/hooks/useComponentRepository';
import Resource from 'src/vendor/Resource';
import ResourceSelector from 'src/components/Custom/ResourceSelector';

export default function Conditional_ProductHasLot({
    productId_key,
    resourceId,
    children
}) {
    const {formData, getValue, getMessages, setValue} = useFormData();
    const {getCustomComponentByKey} = useComponentRepository();
    const [hasLot, setHasLot] = useState(false);
    const superFetch = useSuperFetch();

    const productId = (() => {
        console.log(formData, productId_key);
        if(productId_key.indexOf('.') > -1) {
            const parts = productId_key.split(".");

            switch(parts[0]) {
                case 'formData':
                    return getValue(parts[1]);
                break;
                case 'resource':
                    if(parts[1] == "resource_id") {
                        return resourceId;
                    }
                break;
            }
        }
    })()

    useEffect(() => {

        (async() => {
            if(productId == null) {
                setHasLot(false);
                return;
            }
            if(isNaN(parseInt(productId))) {
                setHasLot(false);
                return;

            }
            const result = await superFetch.get(`products/view/${productId}`);

            if(result && result.data) {
                setHasLot(parseInt(result.data.is_lot) == 1);
            }
        })()

    }, [productId])

    if(hasLot) {
        return <React.Fragment>{children}</React.Fragment>
    }

    return null;

}