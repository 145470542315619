import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import {FormControlLabel, Checkbox as MUICheckbox} from '@mui/material';
import useFormData from 'src/hooks/useFormData';

export default function Checkbox({
    label,
    name,
}) {
    const {formData, getValue, setFormData, getMessages, onValueChanged} = useFormData();

    const onChange = (e) => {
        setFormData({
            ...formData,
            values: {
                ...formData.values,
                
                [name]: getValue(name) == 1?0:1
            }
        });
    }
    return (
        <FormControl error={getMessages(name).length > 0}>
            <FormControlLabel control={<MUICheckbox checked={getValue(name) == 1} name={name} onChange={onChange}/>} label={label} />
            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </FormControl>
    )
}