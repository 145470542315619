import { CircularProgress, MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import { useEffect, useState } from 'react';
import useSuperFetch from 'src/hooks/useSuperFetch';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({theme}) =>({
    width: '100%'
}));

export default function SelectField({
    label,
    name,
    labelKey = 'name',
    valueKey = 'id',
    resourceUrl,
    resourceId,
    resource,
    data,
    value,
    onChange,
    disabled = false,
    messages = []
}) {
    const [menuData, setMenuData] = useState(null);
    const superFetch = useSuperFetch();

    async function getResourceItems() {
        const resourceData = (() => {
            if(typeof data != "object")
                return {};
            const props = data;
      
            Object.keys(props).map(key => {
                if(typeof props[key] == "string") {
                    props[key] = props[key].replace(':resource_id:', resourceId).replace(":resource:", resource)
                }
            });
            
            return props;
        })()
        const r = await superFetch.get(resourceUrl+'?'+superFetch.serialize({...resourceData}));
        setMenuData(r.queryResult.rows);
    }

    useEffect(getResourceItems, []);

    useEffect(() => {
        if(menuData == null)
            return;
        if((menuData.length == 1) && (!value)) {
            //setValue(name, menuData[0][valueKey])
        }
    }, [menuData])

    if(menuData == null) {
        return <CircularProgress />
    }

    const menuItems = menuData.map(i => <MenuItem key={`itemkey-${i[labelKey]}`} value={i[valueKey]}>{i[labelKey]}</MenuItem>)
    
    return (
        <StyledFormControl error={messages.length > 0}>
            <InputLabel id={name} htmlFor={name}>{label}</InputLabel>
            <Select
                labelId={name}
                disabled={disabled}
                value={value}
                onChange={(e) => {onChange(e.target.value)}}
                label={label}
            >
                {menuItems}
            </Select>
            {messages.map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </StyledFormControl>
    )
}