import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import useFormData from 'src/hooks/useFormData';
import { styled } from '@mui/material/styles';
import AutocompleteTextField from './AutocompleteTextField';
import { Editor } from "react-draft-wysiwyg";

import { EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const StyledFormControl = styled(FormControl)(({theme}) =>({
    width: '100%'
}));

export default function DraftWYSIWYGField(opts) {
    const {
        label,
        name,
        disabled = false,
    } = opts;
    const {formData, getValue, setValue, getMessages, onValueChanged} = useFormData();
    // getValue(name)
    const blocksFromHtml = htmlToDraft(getValue(name) || '');
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);


    return (
        <StyledFormControl error={getMessages(name).length > 0}>

            <Editor
                defaultEditorState={EditorState.createWithContent(contentState)}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onContentStateChange={(e) => setValue(name, draftToHtml(e))}
            />
            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </StyledFormControl>
    )
}