import { Navigate, useLocation } from "react-router-dom";
import Page from "src/components/Page";

import React, { useState } from "react";
import PackingReviewView from "src/components/Custom/PackingReview/PackingReviewView";


const breadcrumbs = [
    ['/app/picking/list', 'Szedőlisták', 'Csomagolás']
];


export default function Packing() {
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);

    
    return <React.Fragment>
        
        <Page breadcrumbs={breadcrumbs} title={'Ellenörzés'}>
            <PackingReviewView picklistId={params.get('picklist_id')}/>  
        </Page>

    </React.Fragment>
}