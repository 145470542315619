export function getInitialState() {
    return {
        showSidebar: true,
        sidebarUnfoldable: false,
        tokenId: window.localStorage.getItem('token_id'),
        title: '',
        userData: {
            fullName: ''
        },
        errorMessages: {},
        messages: [],
        beep: function (duration, frequency, volume) {
            return new Promise((resolve, reject) => {
                // Set default duration if not provided
                duration = duration || 200;
                frequency = frequency || 440;
                volume = volume || 100;
                if(window.myAudioContext == null) {
                    window.myAudioContext = new AudioContext();
                }
                try {
                    let oscillatorNode = window.myAudioContext.createOscillator();
                    let gainNode = window.myAudioContext.createGain();
                    oscillatorNode.connect(gainNode);
                        
                    // Set the oscillator frequency in hertz
                    oscillatorNode.frequency.value = frequency;
                        
                    // Set the type of oscillator
                    oscillatorNode.type = "square";
                    gainNode.connect(window.myAudioContext.destination);
                        
                    // Set the gain to the volume
                    gainNode.gain.value = volume * 0.01;
                        
                    // Start audio with the desired duration
                    oscillatorNode.start(window.myAudioContext.currentTime);
                    oscillatorNode.stop(window.myAudioContext.currentTime + duration * 0.001);
                        
                    // Resolve the promise when the sound is finished
                    oscillatorNode.onended = () => {
                        resolve();
                    };
                } catch (error) {
                    reject(error);
                }
            });
        }
    };
}
