import Page from "src/components/Page";
import {FileField, FormGroup, Form, SubmitButton, TextField, Recaptcha} from "src/vendor/FormX";
import {Stack} from '@mui/material'

import { alpha, styled } from '@mui/material/styles';
import useAppState from "src/hooks/useAppState";
import SimpleLink from "src/components/SimpleLink";

export default function() {
    const {dispatch} = useAppState();

    const Wrapper = styled(Page)( (theme) => ({
        width: '100%',
    }) );

    const onSuccessfulLogin = async({result}) => {
        //window.location = '/';
    }

    return <Wrapper title={`Cég adatai`}>
        <Form action='authentication/user_without_client' onSuccess={onSuccessfulLogin}>
            <Stack direction="column" spacing={2}>
                <FormGroup label="Vállalkozás adatai">
                    <Stack direction="column" spacing={2}>
                    <TextField name="company_name" label="Vállalkozás neve" />
                    <TextField name="company_taxnumber" label="Adószáma" />
                    <TextField name="company_registration_number" label="Cégjegyzék száma" />
                    </Stack>
                </FormGroup>
                <FormGroup label="Pénzügy">
                    <Stack direction="column" spacing={2}>
                    <TextField name="bankaccount1" label="Bankszámlaszám" />
                    <TextField name="bankaccount1_currency" label="Devizanem" />
                    <TextField name="contact_financial_email" label="Email cím" />
                    </Stack>
                </FormGroup>

                <FormGroup label="Székhely">
                    <Stack direction="column" spacing={2}>
                    <TextField name="headquarter_country" label="Ország" />
                    <TextField name="headquarter_city" label="Város" />
                    <TextField name="headquarter_zipcode" label="Irányítószám" />
                    <TextField name="headquarter_address" label="Cím" />
                    </Stack>
                </FormGroup>

                <FormGroup label="Kapcsolattartó">
                    <Stack direction="column" spacing={2}>
                    <TextField name="contact_name" label="Neve" />
                    <TextField name="contact_email" label="Email címe" />
                    <TextField name="contact_phone" label="Telefonszáma" />
                    <TextField name="customer_service_phone" label="Ügyfélszolgálat telefonszáma" />
                    </Stack>

                </FormGroup>

                <FormGroup label="">
                    <SubmitButton label={'Cég létrehozása'} />
                </FormGroup>

            </Stack>
        </Form>
    </Wrapper>
}