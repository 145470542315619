import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import useFormData from 'src/hooks/useFormData';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import useSuperFetch from 'src/hooks/useSuperFetch';
import ResourceAutocompleteTextField from './ResourceAutocompleteTextField';

const StyledFormControl = styled(FormControl)(({theme}) =>({
    width: '100%'
}));
const filter = createFilterOptions();
export default function AutocompleteTextField({
    label,
    name,
    type = 'text',
    autocomplete,
    data,
    resourceId,
    resource,
    useValues,
    disabled = false
}) {
    const {formData, getValue, setValue, getMessages, onValueChanged} = useFormData();
    const {get, serialize} = useSuperFetch();

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);

    if(autocomplete.labelKey) {
      return <ResourceAutocompleteTextField {...{label, disabled, useValues, type, name, autocomplete, data, resourceId, resource}} />
    }

    const loading = open && options.length === 0;

    const resourceData = (() => {
        if(typeof data != "object")
            return {};
        const props = data;

        Object.keys(props).map(key => {
            if(typeof props[key] == "string") {
                props[key] = props[key].replace(':resource_id:', resourceId).replace(":resource:", resource)
            }
        });
        
        return props;
    })()
  
    React.useEffect(() => {
      let active = true;
  
      if (!loading) {
        return undefined;
      }
  
      (async () => {
        const r = await get(autocomplete.url+'?'+serialize(resourceData));
        if (active) {
          setOptions(r.data);
        }
      })();
  
      return () => {
        active = false;
      };
    }, [loading]);
  
    React.useEffect(() => {
      if (!open) {
        setOptions([]);
      }
    }, [open]);

    return (
        <StyledFormControl error={getMessages(name).length > 0}>
            <Autocomplete
            
                disabled={disabled}
                id="asynchronous-demo"
                sx={{ width: '100%' }}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(e, newValue) => setValue(name, newValue)}
                value={getValue(name)}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={options}
                loading={loading}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
            
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push(inputValue);
                    }
            
                    return filtered;
                }}
                
                freeSolo
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                    />
                )}
            />

            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </StyledFormControl>
    )
}