import ResourceSelectorContext from "./context";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import NoResourceSelected from "./NoResourceSelected";
import { alpha, styled } from '@mui/material/styles';
import ResourceLogo from "./ResourceLogo";
import ResourceSelected from "./ResourceSelected";

import BarcodeReader from 'react-barcode-reader';

const StyledCard = styled(Card)(({ theme }) => ({
    border: '1px solid #fff',
    borderColor: alpha(theme.palette.grey[700], 0.2),
    marginLeft: 5,
    marginRight: 5
}))
const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
    'display': 'flex',
    'alignItems': 'center',
    'justifyItems': 'center',
    'justifyContent': 'center',
    'alignContent': 'center',
    'padding': '10px',
}))
export default function({resource, data, currentValue, onSelected, onDialogShown, onDialogClosed, onSelectionRemoved, children}) {

    const contextValue = {
        resource,
        data,
        currentValue,
        onSelected,
        onDialogShown,
        onDialogClosed,
        onSelectionRemoved
    };

    const content = (() => {
        if(isNaN(parseInt(currentValue))) {
            return (<NoResourceSelected />)
        }

        return (<ResourceSelected />)

    })()

    return (
        <ResourceSelectorContext.Provider value={contextValue}>
            <StyledCard sx={{ display: 'flex' }}>

                <StyledCardMedia>
                    <ResourceLogo />
                </StyledCardMedia>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {content}

                </Box>
            </StyledCard>
            {children}
        </ResourceSelectorContext.Provider>
    )
}