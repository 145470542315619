import styled from "@emotion/styled"
import { Paper, Typography } from "@mui/material"

export default function FormGroup({label = '', children}) {

    const Group = styled(Paper)(({ theme }) => ({
        padding: '15px',
        borderLeft: `3px solid ${theme.palette.primary.main}`
    }));

    const Header = styled(Typography)(({theme}) => ({
        marginBottom: '15px'
    }));

    return (<Group elevation={2}>
        <Header variant="subtitle1" sx={{ color: 'text.primary' }}>{label}</Header>
        {children}
    </Group>)
}