import { Link, styled } from "@mui/material"
import { useNavigate } from "react-router-dom";

export default function SimpleLink(props) {
    const {children, to} = props;
    const navigate = useNavigate();

    const NewLink = styled(Link)( (theme) => ({
        cursor: 'pointer'
    }) );

    return <NewLink onClick={() => {
        navigate(to, {replace: true})
    }}>{children}</NewLink>
}