import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import useFormData from 'src/hooks/useFormData';
import { styled } from '@mui/material/styles';
import AutocompleteTextField from './AutocompleteTextField';

const StyledFormControl = styled(FormControl)(({theme}) =>({
    width: '100%'
}));

export default function TextField(opts) {
    const {
        label,
        name,
        disabled = false,
        type = 'text',
        autocomplete = null
    } = opts;
    const {formData, getValue, getMessages, onValueChanged} = useFormData();
    if(autocomplete) {
        return <AutocompleteTextField {...opts} />
    }

    return (
        <StyledFormControl error={getMessages(name).length > 0}>
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <OutlinedInput
                id={name}
                disabled={disabled}
                name={name}
                type={type}
                value={getValue(name)}
                onChange={onValueChanged}
                aria-describedby={`${name}-error`}
                label={label}
            />
            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </StyledFormControl>
    )
}