import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import {FormControlLabel, Checkbox as MUICheckbox, Switch} from '@mui/material';
import useFormData from 'src/hooks/useFormData';
import useSuperFetch from 'src/hooks/useSuperFetch';
import { useContext } from 'react';
import ResourceContext from 'src/vendor/Resource/Context';

export default function Order_ReadyToPackField({
    label,
    name,
    resource,
    resourceId
}) {
    const {formData, getValue, setFormData, getMessages, customFns: {fetchRemoteForm}, onValueChanged} = useFormData();
    const {tableViewRef} =useContext(ResourceContext);
    const {post} = useSuperFetch();


    async function onChange(e) {

        const r = await post(`${resource}/set_packability?order_id=${resourceId}&value=${getValue(name) == 1?0:1}`, {});
        if(r.success != 1)
            return;

        setFormData({
            ...formData,
            values: {
                ...formData.values,
                
                [name]: getValue(name) == 1?0:1
            }
        });
        //tableViewRef.current.refreshDataTable();
        if(fetchRemoteForm) {
            fetchRemoteForm();
        }
    }
    return (
        <FormControl error={getMessages(name).length > 0}>
            <FormControlLabel control={<Switch checked={getValue(name) == 1} name={name} onChange={onChange}/>} label={label} />
            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </FormControl>
    )
}