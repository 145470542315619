import { Alert, AlertTitle } from "@mui/material";
import React, { useContext, useEffect, useRef } from "react";
import AppContext from "src/contexts/app/context";

export default function({errorMessageKey}) {
    const _ref = useRef(null);

    const {errorMessages, dispatch} = useContext(AppContext);

    let errors = (() => {
        if(!errorMessages.hasOwnProperty(errorMessageKey))
            return [];

        return errorMessages[errorMessageKey];
    })();

    useEffect(() => {
        if(errors.length == 0)
            return null;
        _ref.current = setTimeout(() => {
            dispatch({type: 'REMOVE_ERROR_MESSAGES', payload: errorMessageKey})
        }, 5000)
        return () => {
            clearTimeout(_ref.current);
        }
    }, [errors])

    if(errors.length == 0) {
        return null
    }

    function removeIt() {
        clearTimeout(_ref.current);
        dispatch({type: 'REMOVE_ERROR_MESSAGES', payload: errorMessageKey})

    }


    let errorComps = errors.map((error, errorI) => {
        
        return (<Alert key={errorI} onClick={removeIt} severity="error">
        <AlertTitle>Hiba</AlertTitle>
        {error}
      </Alert>)
    })

    return <React.Fragment>{errorComps}</React.Fragment>;
}