import { Navigate, useLocation } from "react-router-dom";
import Page from "src/components/Page";

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import React, { useState } from "react";
import { IconButton, Paper, Stack } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import PauseIcon from '@mui/icons-material/Pause';

import { styled } from '@mui/material/styles';

import { default as PickingComp } from "src/components/Custom/Picking/Picking";
import useSuperFetch from "src/hooks/useSuperFetch";


const breadcrumbs = [
    ['/app/picking/list', 'Szedőlisták']
];


export default function Picking() {
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);
    const [value, setValue] = useState(-1);
    const {post} = useSuperFetch();

    /*if(value == 0) {
        return <Navigate to={`/app/putaway/list`} />
    }

    if(value == 1) {
        return <Navigate to={`/app/putaway/list`} />
    }

    async function onChangeValue(value) {
        if(value == 1) {
            await post(`putawaylists/set_status`, {
                putawaylist_id: params.get('putawaylist_id'),
                status_id: 4
            });
        }

        setValue(value);
    }*/
    
    return <React.Fragment>
        
        <Page breadcrumbs={breadcrumbs} title={'Kiszedés'}>
            <PickingComp picklistId={params.get('picklist_id')} />
        </Page>
        
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    onChangeValue(newValue);
                }}
            >
                <BottomNavigationAction label="Szünet" icon={<PauseIcon />} />
                <BottomNavigationAction label="Befejeztem" icon={<CancelIcon />} />
            </BottomNavigation>
        </Paper>
    </React.Fragment>
}