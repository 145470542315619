import { CircularProgress, MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useEffect, useState } from 'react';
import useFormData from 'src/hooks/useFormData';
import useSuperFetch from 'src/hooks/useSuperFetch';
import { styled } from '@mui/material/styles';

const StyledFormControl = styled(FormControl)(({theme}) =>({
    width: '100%'
}));

export default function SelectField({
    label,
    name,
    labelKey = 'name',
    valueKey = 'id',
    resourceUrl,
    resourceId,
    defaultValue = null,
    emptyByDefault = false,
    canBeEmpty = false,
    resource,
    data,
    useValues,
    disabled = false
}) {
    const {formData, getValue, getMessages, setValue} = useFormData();
    const [menuData, setMenuData] = useState(null);
    const superFetch = useSuperFetch();

    function getFormFields() {
        if(Array.isArray(useValues)) {

            const array = useValues.map(i => ({key: i, value: getValue(i)}))
            const mapped = array.map(item => ({ [item.key]: item.value }) );
            const newObj = Object.assign({}, ...mapped );

            return newObj;
        }

        return {};
    }

    async function getResourceItems() {
        const resourceData = (() => {
            if(typeof data != "object")
                return {};
            const props = data;
      
            Object.keys(props).map(key => {
                if(typeof props[key] == "string") {
                    props[key] = props[key].replace(':resource_id:', resourceId).replace(":resource:", resource)
                }
            });
            
            return props;
        })()
        const r = await superFetch.get(resourceUrl+'?'+superFetch.serialize({...resourceData, ...getFormFields()}));
        setMenuData(r.queryResult.rows);
    }

    useEffect(getResourceItems, []);
    useEffect(getResourceItems, [superFetch.serialize(getFormFields())]);



    useEffect(() => {
        if(menuData == null)
            return;
        if(canBeEmpty != false) 
            return;
        if((menuData.length == 1) && (!getValue(name))) {
            setValue(name, menuData[0][valueKey])
        } else {
            if(defaultValue != null) {
                setValue(name, defaultValue);
            }
        }
        
    }, [menuData])

    if(menuData == null) {
        return <CircularProgress />
    }

    const menuItems = menuData.map(i => <MenuItem key={`itemkey-${i[labelKey]}`} value={i[valueKey]}>{i[labelKey]}</MenuItem>)
    
    return (
        <StyledFormControl error={getMessages(name).length > 0}>
            <InputLabel id={name} htmlFor={name}>{label}</InputLabel>
            <Select
                labelId={name}
                
                disabled={disabled}
                value={(getValue(name) == undefined)?'':getValue(name)}
                onChange={(e) => {setValue(name, e.target.value)}}
                label={label}
            >
                {canBeEmpty?<MenuItem value={null}>-</MenuItem>:null}
                {menuItems}
            </Select>
            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </StyledFormControl>
    )
}