
import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StackHorizontalItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    borderRadius: '0',
    boxShadow: 'none',
    '*::-webkit-scrollbar': {
      width: '4px',
      zIndex: 120
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    }
}));

const OrdersWrapper = styled(StackHorizontalItem)(({theme}) => ({
    width: '20%',
    padding: theme.spacing(0),
    borderRight: '1px solid #fff',
    borderRightColor: theme.palette.grey[300],
    '.MuiListSubheader-root:first-child': {
        borderBottom: '1px solid #fff',
        borderBottomColor: theme.palette.grey[300]
    }
}));

const ProductsWrapper = styled(StackHorizontalItem)(({theme}) => ({
    width: '30%',
    padding: theme.spacing(0),
    borderRight: '1px solid #fff',
    borderRightColor: theme.palette.grey[300],
    '.MuiListSubheader-root:first-child': {
        borderBottom: '1px solid #fff',
        borderBottomColor: theme.palette.grey[300]
    }
}));
const PackingWrapper = styled(StackHorizontalItem)(({theme}) => ({
    width: '50%',
    padding: theme.spacing(0),
}));

const SubheaderClone = styled(Box)(({theme}) => ({
    boxSizing: "border-box",
    lineHeight: "48px",
    listStyle: "none",
    color: "#637381",
    fontFamily: "Public Sans,sans-serif",
    fontWeight: 600,
    fontSize: "0.875rem",
    paddingLeft: "16px",
    paddingRight: "16px",
    position: ["-webkit-sticky", "sticky"],
    top: "0",
    zIndex: 1,
    backgroundColor: "#fff",
    borderBottom: '1px solid #fff',
    borderBottomColor: theme.palette.grey[300]
}))

export default {
    StackHorizontalItem,
    OrdersWrapper,
    ProductsWrapper,
    PackingWrapper,
    SubheaderClone
}