import { useContext } from "react";
import ResourceSelectorContext from "./context";

/*
    const superFetch = useSuperFetch();

    superFetch.get(url, data)
*/
export default function useResourceSelector() {
    const ctx = useContext(ResourceSelectorContext);

    return ctx;
}