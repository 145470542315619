import { useLocation } from "react-router-dom";
import { Card, CardContent, CardHeader, Dialog, Paper, Typography } from "@mui/material";
import Page from "src/components/Page";import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import AppContext from "src/contexts/app/context";
import { useEffect, useState, useContext } from "react";
import { alpha, useTheme, styled } from '@mui/material/styles';
import Iconify from "src/components/Iconify";
import { Link as RouterLink } from 'react-router-dom';
import useComponentRepository from "src/hooks/useComponentRepository";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  });

export default function() {
    const {superFetch} = useContext(AppContext);
    const {getCustomComponentByKey} = useComponentRepository();
    const [utilitiesData, setUtilitiesData] = useState([]);

    const [dialogData, setDialogData] = useState(false);

    useEffect(() => {
        (async() => {
          try {
            const r = await superFetch.get('navigation_helper/get_utilities');
            setUtilitiesData(r.data);
          } catch(e) {}
        })()
    }, [])

    const utilityCards = utilitiesData.map((i,k) => {
        if(!i.hasOwnProperty('items'))
            return null;

        const items = i.items.map((i,k) => {
            if(i?.componentPlacement == "DIALOG") {
                return (
                    <MenuItem
                        onClick={() => {
                            setDialogData(i)
                        }}
                        key={k} >
                            <ListItemIconStyle>{i.icon && getIcon(i.icon)}</ListItemIconStyle>
                            <ListItemText>{i.title}</ListItemText>
                    </MenuItem>)
            }
            return (
            <MenuItem
                component={RouterLink}
                to={i.path}
                key={k} >
                    <ListItemIconStyle>{i.icon && getIcon(i.icon)}</ListItemIconStyle>
                    <ListItemText>{i.title}</ListItemText>
            </MenuItem>)
        })

        return (
            <Card
                key={k}
                sx={{minWidth: 300, display: 'flex', flex: 1, margin: '15px', flexDirection: 'column'}}
                variant="outlined"
            >
                <CardHeader 
                    title={i.title}
                />
                <CardContent>
                    <MenuList>
                        {items}
                    </MenuList>

                </CardContent>
            </Card>
        )
    })

    const dialog = (() => {
        if(dialogData == false) {
            return null;
        }

        const Comp = getCustomComponentByKey(dialogData.customComponent);

        function onClose() {
            setDialogData(false);
        }

        return (<Dialog open={true}>
            <Comp {...dialogData} handleClose={onClose}/>
        </Dialog>)
    })()



    return <Page title={`Eszközök`}>


            <div style={{ width: '100%', margin: 5, display: 'flex', flexWrap: 'wrap' }}>
                {utilityCards}
            </div>
            
            {dialog}
    </Page>
}