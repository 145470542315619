import { Navigate, useLocation } from "react-router-dom";
import Page from "src/components/Page";

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import React, { useState } from "react";
import { IconButton, Paper, Stack } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import PauseIcon from '@mui/icons-material/Pause';
import Inventory2Icon from '@mui/icons-material/Inventory2';

import { styled } from '@mui/material/styles';

import { default as PickingComp } from "src/components/Custom/Picking/Picking";
import useSuperFetch from "src/hooks/useSuperFetch";


const breadcrumbs = [
    ['/app/picking/list', 'Szedőlisták']
];


export default function Review() {
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);
    const [value, setValue] = useState(-1);
    const {post} = useSuperFetch();

    if(value == 0) {
        return <Navigate to={`/app/picking/list`} />
    }
    if(value == 2) {
        return <Navigate to={`/app/packing/quick?picklist_id=${params.get('picklist_id')}`} />
    }

    async function onChangeValue(value) {
        await post(`picklists/close`, {
            picklist_id: params.get('picklist_id')
        });

        if(value == 2) {
            setValue(2);
            return;
        }

        setValue(0);
    }
    
    return <React.Fragment>
        
        <Page breadcrumbs={breadcrumbs} title={'Kiszedés'}>
            Lista minden tétele összeszedésre került, kérlek zárd le. (Befejezem)
        </Page>
        
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    onChangeValue(newValue);
                }}
            >
                <BottomNavigationAction label="Kilépés" icon={<PauseIcon />} />
                <BottomNavigationAction label="Befejeztem" icon={<CancelIcon />} />
                <BottomNavigationAction label="Gyors csomagolás" icon={<Inventory2Icon />} />
            </BottomNavigation>
        </Paper>
    </React.Fragment>
}