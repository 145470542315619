import { Chip, List, Box, ListItem, ListItemButton, ListItemText, ListSubheader, Stack, Switch, Typography, Button, ButtonGroup } from "@mui/material";
import React, { useEffect } from "react";
import useSuperFetch from "src/hooks/useSuperFetch";
import StyledElements from "../StyledElements";
import usePackingContext from "../usePackingContext";
import ParcelsView from "./Shipment/ParcelsView";

export default function() {
    const {currentOrderId, currentOrder, actionDeleteShipment, picklistId, actionCloseAndPrintShipment, actionPrintLabel, actionDeleteParcel, actionCloseShipment, shipment, openOrder, refreshOrders, refreshProducts, parcelViewReloadTrigger, dispatch} = usePackingContext();
    const {get} = useSuperFetch()


    const specialInstructions = (() => {
        if(currentOrder && currentOrder.hasOwnProperty('special_packing_instructions') && currentOrder.special_packing_instructions && currentOrder.special_packing_instructions.length > 0) {
            return <Box>
                <span style={{display: 'block', fontWeight: 'bold'}}>Csomagolási utasítás:</span>
                <span style={{display: 'block', fontWeight: 'bold', color: 'red'}}>{currentOrder.special_packing_instructions}</span>
            </Box>
        }

        return null;
    })();
    
    return (
    <>

        <Stack direction='column' sx={{height: '100%'}}>
            <StyledElements.SubheaderClone>Megrendelés</StyledElements.SubheaderClone>
            {/*<Box>
                <Stack direction='row'>
                    <Box>Címzett</Box>
                    <Box>Szállítási mód</Box>
                </Stack>
            </Box>
            <Box>
                Kiemelt utasítások, currentOrderId: {currentOrderId}
            </Box>*/}
            <Box>
                CurrentOrderId: {currentOrderId}<br/>
            </Box>
            {specialInstructions}
            <Box sx={{flex: 1}}>
                <ParcelsView  />
            </Box>
            <Box>
                <ButtonGroup>
                    <Button onClick={actionDeleteShipment} variant='contained' color='error'>Visszadobás</Button>
                    {shipment && shipment.parcels && shipment.parcels.length > 1 ? <Button onClick={actionDeleteParcel}  variant='contained'color='error'>Szállítmány törlése</Button>:null}
                    <Button onClick={actionPrintLabel} variant='contained' color='success'>Nyomtatás</Button>
                    <Button onClick={actionCloseAndPrintShipment} variant='contained' color='success'>Lezárás és nyomtatás</Button>
                    <Button onClick={() => window.location = '/app/packing/review?picklist_id='+picklistId} variant='contained' color='warning'>Csomagok ellenörzése</Button>
                </ButtonGroup>
            </Box>
        </Stack>
    </>
    );
}