import { CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import useSuperFetch from "src/hooks/useSuperFetch";
import ResourceContext from "./Context";
import TableView, { TABLEVIEW_ACTIONS_VIEW } from "./TableView";

import {forwardRef, useImperativeHandle, useRef} from 'react';


export default forwardRef(({name, parent = {resource: null, id: null}, data = {}, specialRowActions = [], reloadTimeout = null, noLoadingState = false, onRefreshFn = null, children}, ref) => {

    const [description, setDescription] = useState(null);
    const superFetch = useSuperFetch();
    const tableViewRef = useRef(null);

    useEffect(() => {
        setDescription(null);
    }, [name]);

    useImperativeHandle(ref, () => ({
        refreshDataTable() {
            if(tableViewRef && tableViewRef.current)
            tableViewRef.current.refreshDataTable()
        }
    }));

    function serialize(obj, prefix) {
        var str = [],
          p;
        for (p in obj) {
          if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
              v = obj[p];
            str.push((v !== null && typeof v === "object") ?
              serialize(v, k) :
              encodeURIComponent(k) + "=" + encodeURIComponent(v));
          }
        }
        return str.join("&");
      }

    useEffect(() => {
        if(description == null) {
            (async() => {
                try {
                    const r = await superFetch.get(`${name}/describe?${serialize(data)}`);
                    setDescription({
                        name: name,
                        ...r.data
                    });
                } catch(e) {
                    console.error("Resource's describe fetch failed with: ", e)
                }
            })();
        }
    }, [name, description])

    if(description == null) {
        return <CircularProgress />
    }

    const contextValue = {
        description,
        data,
        ref,
        parent,
        specialRowActions,
        reloadTimeout,
        noLoadingState,
        tableViewRef,
        onRefreshFn
    };

    return (
        <ResourceContext.Provider value={contextValue}>
            <TableView ref={tableViewRef} />
            {children}
        </ResourceContext.Provider>
    )
})