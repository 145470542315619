import { Navigate, useLocation } from "react-router-dom";
import Page from "src/components/Page";
import React, { useState } from "react";
import useSuperFetch from "src/hooks/useSuperFetch";
import EasyIntake from "src/components/Custom/Delivery/EasyIntake";

const breadcrumbs = [
    ['/app/generic-resource-v2/deliveries', 'Beszállítások']
];


export default function EasyIntakeView() {
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);
    const deliveryId = params.get('delivery_id');
    const {post} = useSuperFetch();

    

    return <React.Fragment>
        
        <Page breadcrumbs={breadcrumbs} title={'EZ Intake #'+deliveryId}>
            <EasyIntake deliveryId={deliveryId} />
        </Page>
        
    </React.Fragment>
}