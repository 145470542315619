import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react"
import useSuperFetch from "src/hooks/useSuperFetch";
import RemoteForm from "../../../FormX/RemoteForm";

export default function EditDrawer({resource, closeOnSubmit = true, resourceId, data, handleClose, handleTableViewRefresh}) {

    const [resourceData, setResourceData] = useState(null);
    const fetch = useSuperFetch();
    
    useEffect(() => {
        (async () => {
            const r = await fetch.get(`${resource}/view/${resourceId}`);
            setResourceData(r.data);
        })()
    }, [resourceId])

    function onSuccess() {
        
        handleTableViewRefresh();
        if(closeOnSubmit)
            handleClose();
    }

    if(resourceData == null) {
        return <CircularProgress />
    }

    return (
        <div>
            <RemoteForm resource={resource} resourceId={resourceId} data={data} remoteFormKey={`edit`} onSuccess={onSuccess} defaultValues={resourceData} />
        </div>
    )
}