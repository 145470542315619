import Page from "src/components/Page";
import AppContext from "src/contexts/app/context";
import {
    MapContainer,
    TileLayer,
    useMap,
    Marker,
    Popup
  } from 'react-leaflet'
import React, { useContext, useEffect, useState } from "react";
import 'leaflet/dist/leaflet.css';
import { styled } from '@mui/material/styles';
import { CircularProgress, List, ListItem, ListItemButton, ListItemText, ListSubheader, Paper, Stack, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Icon } from "leaflet";

const StackHorizontalItem = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    borderRadius: '0',
    boxShadow: 'none',
    '*::-webkit-scrollbar': {
      width: '4px',
      zIndex: 120
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
    }
}));

const OrdersWrapper = styled(StackHorizontalItem)(({theme}) => ({
    width: '20%',
    padding: theme.spacing(0),
    borderRight: '1px solid #fff',
    borderRightColor: theme.palette.grey[300],
    '.MuiListSubheader-root:first-child': {
        borderBottom: '1px solid #fff',
        borderBottomColor: theme.palette.grey[300]
    }
}));

function OrdersTab({orders, isLoadingOrders}) {
    const currentOrderId = 0;
    function openOrder() {}
    if(isLoadingOrders) {
        return <OrdersWrapper sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress /></OrdersWrapper>;
    }

    
    return (
        <OrdersWrapper>
            <List
                disablePadding
                subheader={<ListSubheader disablePadding>Rendelések</ListSubheader>}
                sx={{
                    maxHeight: 'calc(100vh - 88px - 80px - 80px)',
                    overflowY: 'auto'
                }}
            >
                {orders.map((i, k) => 
                    <ListItem disablePadding key={i.reference_id}>
                        <ListItemButton  selected={i.order_id == currentOrderId} onClick={() => openOrder(i.order_id)}>
                            <ListItemText
                                primary={i.reference_id}
                                secondary={
                                    <React.Fragment>
                                    <Typography
                                        sx={{ display: 'block' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {i.shipping_name}
                                    </Typography>
                                    <Typography
                                        sx={{ display: 'block' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {i.shipping_zipcode}, {i.shipping_city}, {i.shipping_address}
                                    </Typography>
                                    </React.Fragment>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                )}

            </List>
        </OrdersWrapper>
    )
}


export default function() {
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);
    const shipmentlistId = params.get('shipmentlist_id');
    const {superFetch} = useContext(AppContext);
    const [orders, setOrders] = useState([]);
    const [isLoadingOrders, setIsLoadingOrders] = useState(true);

    useEffect(() => {
        (async() => {
            const d = await superFetch.get(`map/get_orders_by_shipmentlist?shipmentlist_id=${shipmentlistId}`)

            setOrders(d.data.rows);
            setIsLoadingOrders(false);
        })()
    }, [shipmentlistId]);

    const markerIcon = new Icon({iconUrl : 'https://unpkg.com/leaflet@1.9.3/dist/images/marker-icon.png'});

    return <Page title={`Térkép`}>
        <Stack direction={`row`}  sx={{flex: 1, maxHeight: '100%'}}>
            
            <OrdersTab orders={orders} isLoadingOrders={isLoadingOrders} />
            <StackHorizontalItem sx={{width: '80%'}}>
                <div style={{width: '100%', height: 'calc( 100vh - 200px )'}}>
                    <MapContainer center={[47.5084682, 19.1615288]} zoom={13} scrollWheelZoom={true} style={{ height: 'calc( 100vh - 200px )', width: '100wh' }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        {orders.map(i => <Marker icon={markerIcon} position={[i.geo.lat, i.geo.lng]}>
                            <Popup>
                            {i.reference_id} <br />{i.shipping_name}<br />{[i.shipping_zipcode, i.shipping_city, i.shipping_address].join(', ')}
                            </Popup>
                        </Marker>)}
                    </MapContainer>
                </div>
            </StackHorizontalItem>
        </Stack>

    </Page>
}