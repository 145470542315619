function uuid() {
    const url = URL.createObjectURL(new Blob())
    const [id] = url.toString().split('/').reverse()
    URL.revokeObjectURL(url)
    return id
  }


export default function actions(state, action) {

    switch(action.type) {
        case 'TOGGLE_SIDEBAR':
            return {
                ...state,
                showSidebar: !state.showSidebar
            }
        case 'SET_TITLE':
            return {
                ...state,
                title: action.payload
            }
        case 'SET_SIDEBAR_VISIBILITY':
            return {
                ...state,
                showSidebar: action.payload
            }
        case 'TOGGLE_UNFOLDABLE_SIDEBAR':
            return {
                ...state,
                sidebarUnfoldable: !state.sidebarUnfoldable
            }
        case 'UPDATE_USER_DATA':
            return {
                ...state,
                userData: action.payload
            }
        case 'REMOVE_TOKEN':
            window.localStorage.removeItem('token_id');
        case 'SET_TOKEN':
            if(action.payload == null) {
                window.localStorage.removeItem('token_id');
            } else {
                window.localStorage.setItem('token_id', action.payload)
            }
            return {
                ...state,
                tokenId: action.payload
            }
        case 'ADD_ERROR_MESSAGES':
            const {errorMessageKey, messages} = action.payload;
            state.beep(50, 100, 100);
            return {
                ...state,
                errorMessages: {
                    ...state.errorMessages,
                    [errorMessageKey]: [
                        ...(state.errorMessages.hasOwnProperty(errorMessageKey)?state.errorMessages[errorMessageKey]: []),
                        ...messages
                    ]
                }
            }

        case 'REMOVE_ERROR_MESSAGES':
            return {
                ...state,
                errorMessages: {
                    
                    ...(Object.keys(state.errorMessages).
                    filter((key) => key != action.payload).
                    reduce((cur, key) => { return Object.assign(cur, { [key]: state.errorMessages[key] })}, {}))
                }
            }
        case 'ADD_MESSAGE': 
            const newMessage = {uid: uuid(), severity: action.payload.severity, message: action.payload.message}
            state.beep(50, newMessage.severity=='success'?1000:100, 100);
            return {
                ...state,
                messages: [
                    ...state.messages,
                    newMessage
                ]
            }

        case 'REMOVE_MESSAGE':
            return {
                ...state,
                messages: state.messages.filter(i => i.uid != action.payload)
            }
        
            
        
    }
}
