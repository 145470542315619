import { useLocation, useParams } from "react-router-dom";
import { CircularProgress, Paper } from "@mui/material";
import Page from "src/components/Page";
import Resource from "src/vendor/Resource";
import { useEffect, useState } from "react";
import useSuperFetch from "src/hooks/useSuperFetch";

import { styled } from '@mui/material/styles';

export default function() {
    const { resource } = useParams();
    const [resourceData, setResourceData] = useState(null);
    const superFetch = useSuperFetch();

    async function getResourceDetails() {
        const d = await superFetch.get(`${resource}/describe`);
        setResourceData(d.data);
    }

    useEffect(() => {
        getResourceDetails();
    }, [resource])
    
    if(resourceData == null) {
        return <CircularProgress />
    }


    return <Page breadcrumbs={resourceData.breadcrumbs} title={resourceData.title}>
        
            <div style={{ width: '100%' }}>
                <Resource name={resourceData.name}  />
            </div>
    </Page>
}