export function getInitialState() {
    return {
        orders: [],
        products: [],
        shipment: null,
        currentOrderId: null,
        currentOrder: null,
        currentShipmentId: null,
        picklistId: null,
        showOnlyOrderProducts: true,

        parcelViewReloadTrigger: 0
    };
}
