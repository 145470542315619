import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react"
import RemoteForm from "../../../FormX/RemoteForm";

export default function CreateDrawer({resource, resouceId, data, remoteFormKey = null, handleClose, handleTableViewRefresh}) {
    function onSuccess() {
        
        handleTableViewRefresh();
        handleClose();
    }

    const _remoteFormKey = (remoteFormKey != null)?remoteFormKey:'create';

    return (
        <div>
            <RemoteForm resource={resource} resourceId={resouceId} remoteFormKey={_remoteFormKey} data={data} defaultValues={data} onSuccess={onSuccess} />
        </div>
    )
}