import { Navigate, useLocation } from "react-router-dom";
import Page from "src/components/Page";

import React, { useState } from "react";
import QuickPackingView from "src/components/Custom/Packing/QuickPackingView";


const breadcrumbs = [
    ['/app/picking/list', 'Szedőlisták']
];


export default function Packing() {
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);

    
    return <React.Fragment>
        
        <Page sx={{
            '>.MuiBox-root': {
                display: 'none'
            },
            '>.MuiPaper-root': {
                width: '100%',
                margin: 0,
                padding: 0
            }
        }} title={'Gyors csomagolás'}>
            <QuickPackingView picklistId={params.get('picklist_id')}/>  
        </Page>

    </React.Fragment>
}