import { Paper, Card, CardContent, Button, CardActions, Typography, Stack, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { clone } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { alpha, styled } from '@mui/material/styles';

import BarcodeReader from 'react-barcode-reader';
import useSuperFetch from "src/hooks/useSuperFetch";
import ResourceSelector from "../ResourceSelector";
import Resource from "src/vendor/Resource";
import RemoteForm from "src/vendor/FormX/RemoteForm";

const InfoCard = styled(Card)(({ theme }) => ({
    border: '1px solid #fff',
    borderColor: alpha(theme.palette.grey[700], 0.2)
}))

const StyledStack = styled(Stack)(({ theme }) => ({

}))

const Item = styled(Paper)(({ theme }) => ({

        marginLeft: 5,
        marginRight: 5

}))

function SwitchShipmentCourier({resourceId: shipmentId, row}) {
    const orderId = row.order_id;
    const {post, get} = useSuperFetch();
    const [selectedCourierId, setSelectedCourierId] = useState(null);
    const [resourceData, setResourceData] = useState(null);

    
    useEffect(() => {
        if(shipmentId == null)
            return;

        (async () => {
            const r = await get(`shipments/get_courier_switch_details/${shipmentId}`);
            setResourceData(r.data);
        })()
    }, [shipmentId])


    if(resourceData == null) {
        return <CircularProgress />
    }


    return (
        <Box elevation={2}>
            <StyledStack  spacing={2}>
                <Item>
                    <InfoCard>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Futárszolgálat módosítása
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                
                            </Typography>

                            <RemoteForm resource={`shipments`} resourceId={shipmentId} remoteFormKey={`switch_courier`} defaultValues={resourceData} />

                        </CardContent>
                        <CardActions>
                            
                        </CardActions>
                    </InfoCard>
                </Item>

                

               
            </StyledStack>
        </Box>

    )
}

export default  SwitchShipmentCourier;