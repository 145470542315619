import Page from "src/components/Page";
import {FileField, FormGroup, Form, SubmitButton, TextField} from "src/vendor/FormX";
import {Container, Stack} from '@mui/material'

export default function() {
    return <Page title={`FormX Test`}>
        <Container maxWidth="sm">
        <Form action='authentication/login'>
            <FormGroup label='Belépés'>
                <Stack direction="column" spacing={2}>
                    <TextField label={'Email'} name={'email'} />
                    <TextField label={'Jelszó'} name={'password'} type={'password'} />
                    <SubmitButton label={'Küldés'} />
                </Stack>
            </FormGroup>
        </Form>

        </Container>
    </Page>
}