import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import useFormData from 'src/hooks/useFormData';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';
import useSuperFetch from 'src/hooks/useSuperFetch';

const StyledFormControl = styled(FormControl)(({theme}) =>({
    width: '100%'
}));
const filter = createFilterOptions();
export default function ResourceAutocompleteTextField({
    label,
    name,
    type = 'text',
    autocomplete,
    data,
    resourceId,
    resource,
    useValues,
    disabled = false
}) {
    const {formData, getValue, setValue, getMessages, onValueChanged} = useFormData();
    const {get, serialize} = useSuperFetch();

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    function getFormFields() {
      if(Array.isArray(useValues)) {

          const array = useValues.map(i => ({key: i, value: getValue(i)}))
          const mapped = array.map(item => ({ [item.key]: item.value }) );
          const newObj = Object.assign({}, ...mapped );

          return newObj;
      }

      return {};
  }
  
    const resourceData = (() => {
        if(typeof data != "object")
            return {};
        const props = data;

        Object.keys(props).map(key => {
            if(typeof props[key] == "string") {
                props[key] = props[key].replace(':resource_id:', resourceId).replace(":resource:", resource)
            }
        });
        
        return props;
    })()
  
    React.useEffect(() => {
      let active = true;
  

      (async () => {
        const r = await get(autocomplete.url+'?'+serialize(resourceData)+"&"+serialize(getFormFields()));
        if (active) {
          
          if(autocomplete?.idKey) {
            setOptions(r.queryResult.rows.map(i => ({id: i[autocomplete.idKey], label: i[autocomplete.labelKey]})));
          } else {
            setOptions(r.queryResult.rows.map(i => i[autocomplete.labelKey]));
          }
          
        }
      })();
  
      return () => {
        active = false;
      };
    }, [open]);
  
    React.useEffect(() => {
      if (!open) {
        //setOptions([]);
      }
    }, [open]);

    let value = getValue(name);

    if(autocomplete?.multiple) {
      if(options.length == 0) {
        value = [];
      } else {
        let selectedValues = value.split(",").filter(i => i.length > 0).map(i => parseInt(i));

        value = options.filter(i => selectedValues.indexOf(parseInt(i.id)) > -1);
      }

      //value = value.filter(i => i.length > 0)
    }

    return (
        <StyledFormControl error={getMessages(name).length > 0}>
            <Autocomplete
                multiple={autocomplete?.multiple}
                
                disabled={disabled}
                id="asynchronous-demo"
                sx={{ width: '100%' }}
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={(e, newValue) => {
                    if(autocomplete?.multiple) {
                      setValue(name, newValue.map(i => i.id).join(","))
                      return;
                    }
                    if(Object.hasOwn(newValue, 'id')) {
                      setValue(name, newValue.id)
                      return;
                    }
                    setValue(name, newValue)
                  }
                }
                value={value}
                isOptionEqualToValue={(option, value) => {
                    if(autocomplete?.idKey && Object.hasOwn(option, 'id')) {
                      return option['id'] == value;
                    } else {
                      return option === value;
                    }
                  }
                }
                getOptionLabel={(option) => {
                    
                    if(autocomplete?.idKey && Object.hasOwn(option, 'label')) {
                      return option['label'];
                    } else {
                      return option;
                    }
                  }
                }
                options={options}
                loading={loading}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if(autocomplete?.enableNewValues == true) {
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push(inputValue);
                      }
                    }
            
                    return filtered;
                }}
                
                freeSolo
                renderInput={(params) => (
                    <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                        ),
                    }}
                    />
                )}
            />

            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </StyledFormControl>
    )
}