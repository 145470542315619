import { MoreVert as MoreVertIcon } from "@mui/icons-material"
import { Avatar, Chip, Divider, IconButton, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Paper, Stack, Typography } from "@mui/material"
import { styled } from '@mui/material/styles';
import React from "react";

const StackItem = styled(Paper)(({ theme }) => ({
    border: 'none',
    borderBottom: 'none',
    boxShadow: 'none',
    background: 'none'
}));

const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel',
];

const ITEM_HEIGHT = 48;

export default function({product_id, editing = false, product_title, image_url, location_name, quantity, selected = false, pickedQuantity = 0, onPick = () => {}}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    function secondaryActions() {
        return null;

        return (
        <React.Fragment>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option) => (
                <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleClose}>
                    {option}
                </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
        )
    }

    const pickedQuantityStack = (() => {
        if(selected == false)
            return null;

        return (
            <StackItem onClick={() => onPick(parseInt(quantity))}>
                <Chip size="small" color="success" label={`${pickedQuantity} db`} />
        </StackItem>
        )
    })()
    
    return (
    <React.Fragment key={product_id}>
        <ListItem
            alignItems="flex-start"
            secondaryAction={secondaryActions()}
            sx={{
                backgroundColor: (selected?'#afffa5':'initial')
            }}
        >
            <ListItemAvatar>
                <Avatar alt={product_title} src={image_url} />
            </ListItemAvatar>
            <ListItemText
            primary={product_title}
            secondary={
                <React.Fragment>
                    <Stack direction="row" spacing={1}>
                        <StackItem>
                            
                            <Chip size="small" color="primary" label={location_name} />
                        </StackItem>

                        <StackItem>
                            <Chip size="small" color="primary" label={`${quantity} db`} />
                        </StackItem>

                        { pickedQuantityStack }

                    </Stack>

                </React.Fragment>
            }
            />
        </ListItem>
        <Divider variant="inset" component="li" />
    </React.Fragment>
    )
}