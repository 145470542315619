function uuid() {
    const url = URL.createObjectURL(new Blob())
    const [id] = url.toString().split('/').reverse()
    URL.revokeObjectURL(url)
    return id
  }

export default function actions(state, action) {

    switch(action.type) {

        case 'SET_ORDERS':
            return {
                ...state,
                orders: action.payload,
                currentOrderId: null,
                currentShipmentId: null
            }

        case 'SET_PICKLIST':
            return {
                ...state,
                picklistId: action.payload
            }

        case 'SET_PRODUCTS':
            return {
                ...state,
                products: action.payload
            }
        case 'SET_CURRENT_ORDER_ID':
            return {
                ...state,
                currentOrderId: action.payload
            }

        case 'SET_CURRENT_ORDER':
            return {
                ...state,
                currentOrder: action.payload
            }

        case 'SET_SHIPMENT':
            return {
                ...state,
                shipment: action.payload
            }

        case 'RELOAD_PARCEL_VIEW':
            return {
                ...state,
                parcelViewReloadTrigger: parseInt(state.parcelViewReloadTrigger)+1
            }

        case 'SET_SHOWONLYORDERPRODUCTS':
            return {
                ...state,
                showOnlyOrderProducts: action.payload
            }
    }
}
