import { Paper, Card, CardContent, Button, CardActions, Typography, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { clone } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";

import { alpha, styled } from '@mui/material/styles';

import BarcodeReader from 'react-barcode-reader';
import AppErrorAlert from "src/components/AppErrorAlert";
import AppContext from "src/contexts/app/context";
import useSuperFetch from "src/hooks/useSuperFetch";
import Resource from "src/vendor/Resource";
import ResourceSelector from "../ResourceSelector";

const InfoCard = styled(Card)(({ theme }) => ({
    border: '1px solid #fff',
    borderColor: alpha(theme.palette.grey[700], 0.2)
}))

const StyledStack = styled(Stack)(({ theme }) => ({

}))

const Item = styled(Paper)(({ theme }) => ({

        marginLeft: 5,
        marginRight: 5

}))

let _processTimer;
function ManualAdd({deliveryId}) {
    const intakeProductsRef = useRef(null);
    const intakLocationsRef = useRef(null);
    const [scanQueue, setScanQueue] = useState([]);
    const [currentToteLocationId, setCurrentToteLocationId] = useState(null);
    const superFetch = useSuperFetch();


    function onScan(scan) {
        if(scan.indexOf("LOC-") === 0) {
            setCurrentToteLocationId(scan.split("-")[1]);
            return;
        }
        setScanQueue([...scanQueue, scan]);
    }

    async function doUpload(scans) {
        
        let resp = await superFetch.post("deliveries/manual_add", {delivery_id: deliveryId, tote_location_id: currentToteLocationId, scans});

        intakeProductsRef.current.refreshDataTable();
        intakLocationsRef.current.refreshDataTable();

        if(resp == null) {
            setScanQueue([...scans])
        }
    }

    useEffect(() => {
        if(scanQueue.length == 0)
            return;
        _processTimer = setTimeout(() => {

            let c = clone(scanQueue);
            setScanQueue([]);
            
            doUpload(c)
        }, 800)
        return () => clearTimeout(_processTimer);
    })

    return (
        <Box elevation={2}>
            <BarcodeReader onScan={onScan} minLength={5} />
            <StyledStack  spacing={2}>
                <Item>
                    <AppErrorAlert errorMessageKey={'INTAKE_ERRORS'} />
                </Item>
                <Item>
                    <Resource ref={intakeProductsRef} name={`delivery_products`} data={{tote_location_id: currentToteLocationId, delivery_id: deliveryId}} />
                </Item>
                

               
            </StyledStack>
        </Box>

    )
}

export default  ManualAdd;