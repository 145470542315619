import Image from "./Image";
import OrderHash from "./OrderHash";

export default function(cellRenderer) {

    switch(cellRenderer) {
        case 'image':
            return Image;
        case 'orderhash':
            return OrderHash;
    }
}