import {createContext} from 'react';

const FormXContext = createContext({
    initialValues: {},
    values: {},
    messages: {},
    isSubmitting: false,
    hasSubmitted: false
});

export default FormXContext;