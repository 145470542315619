import { List, ListItem, ListItemButton, ListItemText, ListSubheader, Typography } from "@mui/material";
import React from "react";
import StyledElements from "../StyledElements";
import usePackingContext from "../usePackingContext";

export default function() {
    const {orders, currentOrderId, openOrder} = usePackingContext();

    return (
    <StyledElements.OrdersWrapper>
        <List
            disablePadding
            subheader={<ListSubheader disablePadding>Rendelések</ListSubheader>}
            sx={{
                maxHeight: 'calc(100vh - 88px - 80px - 80px)',
                overflowY: 'auto'
            }}
        >
            {orders.map((i, k) => 
                <ListItem disablePadding key={i.reference_id}>
                    <ListItemButton  selected={i.order_id == currentOrderId} onClick={() => openOrder(i.order_id)}>
                        <ListItemText
                            primary={i.reference_id}
                            secondary={
                                <React.Fragment>
                                <Typography
                                    sx={{ display: 'block' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {i.shipping_name}
                                </Typography>
                                <Typography
                                    sx={{ display: 'block' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    {i.shipping_zipcode}, {i.shipping_city}, {i.shipping_address}
                                </Typography>
                                <Typography
                                    sx={{ display: 'block' }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                >
                                    CSOMAGOLVA/NEM CSOMAGOLVA
                                </Typography>
                                </React.Fragment>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            )}

        </List>
    </StyledElements.OrdersWrapper>
    );
}