import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
// layouts
import DefaultLayout from './layouts/default';
import AuthLayout from './layouts/auth';
//
import Login from './pages/authentication/Login';
import Signup from './pages/authentication/Signup';
import DashboardApp from './pages/dashboard';
import FormXTest from './pages/sandbox/FormXTest';
import GuardedRoute from './helpers/GuardedRoute';
import UserWithoutClient from './pages/authentication/UserWithoutClient';
import ProductsXlsImport from './pages/products/xls-import';
import GenericResource from './pages/generic-resource';
import Utilities from './pages/utilities';
import GenericResourceV2 from './pages/generic-resource-v2';
import PutawayList from './pages/putaway/list';
import PutawayEdit from './pages/putaway/edit';
import PutawayProgress from './pages/putaway/progress';
import Picking from './pages/picking/picking';
import Review from './pages/picking/review';
import PicklistList from './pages/picking/list';
import Packing from './pages/packing';
import PackingReview from './pages/packing-review';
import Map from './pages/map';
import UnpackView from './pages/shipment/unpack-view';
import EasyIntakeView from './pages/delivery/easy-intake-view';
import CycleCount from './pages/stock/cycle-count';
import QuickMove from './pages/putaway/quick-move';
import QuickPacking from './pages/quick-packing';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/app',
      element: <GuardedRoute element={<DashboardLayout />} />,
      children: [
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'clients', element: <Outlet />, children: [
            
            { path: 'user_without_client', element: <span>No client for this user</span> }
          ]
        },
        { path: 'putaway', element: <Outlet />, children: [
          { path: 'list', element: <PutawayList /> },
          { path: 'edit', element: <PutawayEdit /> },
          { path: 'progress', element: <PutawayProgress /> },
          { path: 'quick-move', element: <QuickMove /> },
        ]},
        { path: 'picking', element: <Outlet />, children: [
          { path: 'list', element: <PicklistList /> },
          { path: 'pick', element: <Picking /> },,
          { path: 'review', element: <Review /> },
        ]},
        {
          path: 'packing', element: <Outlet />, children : [
            { path: '', element: <Packing /> },
            { path: 'review', element: <PackingReview /> },
            { path: 'quick', element: <QuickPacking /> }
          ]
        },
        { path: 'generic-resource', element: <GenericResource /> },
        { path: 'generic-resource-v2/:resource', element: <GenericResourceV2 /> },
        { path: 'utilities', element: <Utilities /> },

        { path: 'sandbox', element: <Outlet />, children: [
            { path: 'formx', element: <FormXTest /> },
            { path: 'map', element: <Map /> }
          ]
        },

        { path: 'shipment', element: <Outlet />, children: [
            { path: 'unpack', element: <UnpackView /> }
          ]
        },

        { path: 'delivery', element: <Outlet />, children: [
            { path: 'easy-intake', element: <EasyIntakeView /> }
          ]
        },

        { path: 'stock', element: <Outlet />, children: [
            { path: 'cycle-count', element: <CycleCount /> }
          ]
        },
        

      ]
    },
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: 'login', element: <Login /> },
        { path: 'signup', element: <Signup /> },
        { path: 'user_without_client', element: <UserWithoutClient /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}