import React from 'react'
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import Router from './routes';


function App() {

  return (
    <ThemeConfig>
      <GlobalStyles />
      <Router />
    </ThemeConfig>
  )
}

export default App
