import { useEffect, useReducer, useState } from "react";
import actions from "./actions";
import { getInitialState } from "./getInitialState";
import { useNavigate } from "react-router-dom";
import DataGridEventsContext from "./context";
import useSuperFetch from "src/hooks/useSuperFetch";


export default function({children}) {
    const [state, dispatch] = useReducer(actions, getInitialState())
    const navigate = useNavigate();
    const superFetch = useSuperFetch();
    
    const dispatchEvent = (key, args, refreshFn, datatableIsLoadingState) => {
        if(state.hasOwnProperty(key)) {
            state[key]({ //
                row: args[0],
                action: args[1],
                resource: args[2],
                data: args[3],
                refresh: refreshFn,
                datatable: {
                    isLoading: datatableIsLoadingState[0],
                    setLoading: (val) => {datatableIsLoadingState[1](val)}
                    
                },
                superFetch,
                navigate
            });
        }

    }

    return <DataGridEventsContext.Provider value={{
        ...state,
        dispatchEvent,
        dispatch
    }}>
        {children}
    </DataGridEventsContext.Provider>
}