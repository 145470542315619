import useResourceSelector from "./useResourceSelector";
import CardContent from '@mui/material/CardContent';
import React, { useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { CircularProgress, Button, Dialog, MenuItem, Select, LinearProgress } from '@mui/material';
import Resource from "src/vendor/Resource";
import useSuperFetch from "src/hooks/useSuperFetch";




export default function() {

    const {resource, currentValue, onSelectionRemoved} = useResourceSelector();
    const superFetch = useSuperFetch();
    const [resourceData, setResourceData] = useState(null);

    useEffect(() => {
        (async () => {
            const r = await superFetch.get(`${resource}/view/${currentValue}`);
            setResourceData(r.data);
        })()
    }, [currentValue])

    if(resourceData == null) {
        return (
            <React.Fragment>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <LinearProgress />
                </CardContent>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                    {resourceData?.name || resourceData?.title}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    {resource}
                </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                <IconButton onClick={onSelectionRemoved}  aria-label="previous">
                    <DeleteIcon />
                </IconButton>
            </Box>
        </React.Fragment>
    )
}