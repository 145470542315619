import useResourceSelector from "./useResourceSelector";
import CardContent from '@mui/material/CardContent';
import React, { useState } from "react";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { CircularProgress, Button, Dialog, MenuItem, Select } from '@mui/material';
import Resource from "src/vendor/Resource";




export default function() {

    const {resource, data, onSelected} = useResourceSelector();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    function onSearch() {
        setIsDialogOpen(true);
    }

    const DialogWrapper = (() => {
        if(isDialogOpen == false)
            return null;

        function SelectButton({onClick}) {

            return <Button onClick={onClick} size="small" variant="outlined">kiválaszt</Button>;
        }

        return (
                <Dialog maxWidth={`lg`} fullWidth={true} open={true} onClose={() => setIsDialogOpen(false)}>
                    <Resource data={data} name={resource} specialRowActions={[
                        {icon: 'fa-eye', label: 'Kijelölés', onClick: (row) => onSelected(row.id), component: SelectButton}
                    ]} />
                </Dialog>
        )
    })();

    return (
        <React.Fragment>
            <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                    Nincs kijelölés
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" component="div">
                    
                </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                <IconButton onClick={onSearch}  aria-label="previous">
                    <SearchIcon />
                </IconButton>
            </Box>

            {DialogWrapper}
        </React.Fragment>
    )
}