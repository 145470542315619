import { Tab, Box, Tabs, Typography, CircularProgress, Alert, Button, IconButton, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import usePackingContext from "../../usePackingContext";

import BarcodeReader from 'react-barcode-reader';
import useNotify from "src/hooks/useNotify";
import useSuperFetch from "src/hooks/useSuperFetch";
import Resource from "src/vendor/Resource";
import DeleteIcon from '@mui/icons-material/Delete';
import SelectField from "src/vendor/UnForm/Inputs/SelectField";


window.hasScanned = false;
function TabPanel(props) {
    const { children, shipmentParcelId, value, index, ...other } = props;

    const {shipment} = usePackingContext();

    const {post} = useSuperFetch();
    const resourceRef = useRef(null);
    const [packaging, setPackaging] = useState({primary_packaging_id: 1, secondary_packaging_id: 1})
    const packagingRef = useRef(null)

    useEffect(() => {
        console.log("reload parcel view")
        if(resourceRef && resourceRef.current)
            resourceRef.current.refreshDataTable();

    }, [shipment])

    function SelectButton({onClick}) {
        return  <IconButton onClick={onClick} size="small" variant="outlined"><DeleteIcon /></IconButton>;
    }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            <React.Fragment>
                <Box sx={{ p: 3 }}>
                    <Stack direction={`row`}>
                            
                        <SelectField label={`Elsődleges csomagoló anyag`} data={{product_type_id: 2}} onChange={(i) => setPackaging({...packaging, primary_packaging_id: i})} value={packaging.primary_packaging_id} labelKey={`title`}  resource={`products`} resourceUrl={`products/list`} />
                        <SelectField label={`Másodlagos csomagoló anyag`} data={{product_type_id: 2}} onChange={(i) => setPackaging({...packaging, secondary_packaging_id: i})} value={packaging.secondary_packaging_id} labelKey={`title`} resource={`products`} resourceUrl={`products/list`} />
                    </Stack>

                </Box>
                <Box sx={{ p: 3, paddingTop: 0 }}>
                    <Resource
                        ref={resourceRef}
                        name='shipment_parcel_products'
                        data={{shipment_parcel_id: shipmentParcelId}}
                    />
                </Box>
            </React.Fragment>

        )}
      </div>
    );
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
  
export default function() {
    const {products, currentOrder, currentOrderId, shipment, dispatch} = usePackingContext();
    const {post} = useSuperFetch();
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
        if(shipment && shipment.parcels) {
            setValue(shipment.parcels.length-1);
        }
    }, [shipment])


    
    if(shipment == null || shipment.parcels == null || shipment.parcels.length == 0) {
        return (
            <React.Fragment>
                <Box sx={{ width: '100%' }}>
                    <Alert severity="success" color="primary">Ez a csomag még nincs befejezve.</Alert>
                </Box>
            </React.Fragment>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                {shipment.parcels.map((i,k) => <Tab key={i.id} label={`#${i.id}`} {...a11yProps(k)} />)}
            </Tabs>
          </Box>
          {shipment.parcels.map((i, k) => <TabPanel shipmentParcelId={i.id} value={value} index={k} key={i.id} />)}
        </Box>
      );
}