import useFormData from 'src/hooks/useFormData'
import {Button} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';

export default function SubmitButton({label}) {
    const {formData: {isSubmitting}, onSubmit} = useFormData();

    if(isSubmitting) {
        return <LoadingButton loading variant="outlined">
        {label}
      </LoadingButton>
    }
    return (
        <Button type="submit" onClick={() => onSubmit()} variant="contained">{label}</Button>
    )
}