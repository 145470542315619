import React from "react";
import useListingQuery from "src/hooks/useListingQuery";
import useSuperFetch from "src/hooks/useSuperFetch";
import Item from "./ProductList/Item";
import BarcodeReader from 'react-barcode-reader';
import List from "./ProductList/List";
import { Chip, Stack } from "@mui/material";

export default function({putawaylistId}) {
    const superFetch = useSuperFetch();

    const {data: productRows, refresh: refreshProducts} = useListingQuery({url: `putawaylist_location_products/list`, data: {
        putawaylist_id: putawaylistId
    }});
    const {data: locationRows, refresh: refreshLocations} = useListingQuery({url: `putawaylist_locations/list`, data: {
        putawaylist_id: putawaylistId
    }});

    async function onScan(scan) {
        await superFetch.post("putawaylists/assign_location", {
            putawaylist_id: putawaylistId,
            location_id: scan
        });

        refreshProducts();
        refreshLocations();
    }
    
    const items = productRows?.map((item) => (<Item key={item.product_id} editing={true} {...item} />))
    const locations = locationRows?.map((item) => (<Chip key={item.location_id} label={item.location_name} />))

    return (<React.Fragment>
        <Stack direction={'column'}>
            <BarcodeReader  minLength={5} onScan={onScan} />
            <div>
                {locations}
            </div>
            <List>
                { items }
            </List>
        </Stack>

    </React.Fragment>)
}