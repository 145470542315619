import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import {FormControlLabel, Checkbox as MUICheckbox, Switch, Button} from '@mui/material';
import useFormData from 'src/hooks/useFormData';
import useSuperFetch from 'src/hooks/useSuperFetch';
import { useContext } from 'react';
import ResourceContext from 'src/vendor/Resource/Context';

export default function Event_Button({
    label,
    name,
    resourceId,
    action,
    areyousure = false,
    color = 'success',
    variant = 'contained',
    data
}) {
    const {formData, getValue, setFormData, getMessages, customFns: {fetchRemoteForm}, onValueChanged} = useFormData();
    const {tableViewRef} =useContext(ResourceContext);
    const {post} = useSuperFetch();


    async function onClick() {
        if(areyousure) {
            const t = prompt("Biztos? (igen)");
            if(t == 'igen') {
                const r = await post(action.replace(':resource_id:', resourceId), {});
                if(r.success != 1)
                    return;
    
                tableViewRef.current.refreshDataTable();
                if(fetchRemoteForm) {
                    fetchRemoteForm();
                }
            }
        } else {
            const r = await post(action.replace(':resource_id:', resourceId), {});
            if(r.success != 1)
                return;

            tableViewRef.current.refreshDataTable();
            if(fetchRemoteForm) {
                fetchRemoteForm();
            }
        }
    }
    return (
        <FormControl sx={{width: '100%'}} error={getMessages(name).length > 0}>
            <Button sx={{width: '100%'}} variant={variant} color={color} onClick={onClick}>{label}</Button>
            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </FormControl>
    )
}