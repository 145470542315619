import { useEffect, useReducer, useState } from "react";
import ComponentRepositoryContext from "./context";
import actions from "./actions";
import { getInitialState } from "./getInitialState";
import { useNavigate } from "react-router-dom";


export default function({children}) {
    const [state, dispatch] = useReducer(actions, getInitialState())
    const navigate = useNavigate();
    
    const getCustomComponentByKey = (key) => {
        if(state.hasOwnProperty(key)) {
            return state[key];
        }

    }

    return <ComponentRepositoryContext.Provider value={{
        ...state,
        getCustomComponentByKey,
        dispatch
    }}>
        {children}
    </ComponentRepositoryContext.Provider>
}