import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useContext, useState } from 'react';
import DataGridEventsContext from 'src/contexts/datagrid-events/context';
import { TextField, Form, SubmitButton } from 'src/vendor/FormX';


export default function PromptDialogWithEvent({handleClose, dialogTitle, dialogMessage, resource, event, eventParams = {}, resourceId, label, handleTableViewRefresh}) {
    const {dispatchEvent: dispatchDaragridEvent} = useContext(DataGridEventsContext);
    function onError() {

    }

    function onSuccess() {
        if(handleTableViewRefresh)
            handleTableViewRefresh();
        if(handleClose)    
            handleClose();
    }

    function onSubmit(el) {
        console.log("resource: ", resource);
        console.log("resourceId ", resourceId);
        console.log("triggering event", event);
        console.log("eventParams", eventParams)
        console.log("form", el)

        dispatchDaragridEvent(event, [{id: resourceId},null,null,{
            action: eventParams.action,
            data: el
        }], () => {
            handleClose();
        }, [null, () => {}]);
    }

    return (
        <React.Fragment>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{dialogMessage}</DialogContentText>
                <Form action={null} onSuccess={onSuccess} onError={onError} onSubmit={onSubmit} initialValues={{}}>
                    <TextField name={`value`} label={label} />
                    <SubmitButton label={'Rendben'} />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Mégsem</Button>
            </DialogActions>
        </React.Fragment>
    )
}