import { DataGrid, gridClasses , GridActionsCellItem, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter, useGridApiContext } from '@mui/x-data-grid';
import useListingQuery from "src/hooks/useListingQuery";
import Iconify from "src/components/Iconify";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {forwardRef, useImperativeHandle} from 'react';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { Button, ButtonBase, ButtonGroup, Dialog, Modal  } from '@mui/material';

import { alpha, styled } from '@mui/material/styles';
import ComponentRepositoryContext from 'src/contexts/component-repository/context';
import DataGridEventsContext from 'src/contexts/datagrid-events/context';
import ResourceContext from '../Context';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import useResponsive from 'src/hooks/useResponsive';
import CellRenderer from './CellRenderer';
import AppErrorAlert from 'src/components/AppErrorAlert';

const ODD_OPACITY = 0.2;

const RightHandedBox = styled(Box)(({theme}) => ({
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
}))

function CustomToolbar({actions, toolbar, onActionSelected}) {
    const apiRef = useGridApiContext();

    /**
     * 
     * apiRef.current.getSelectedRows() to get selected rows
     */

    const leftActionButtons = actions.filter(i => i.placement == 'IN_MENU').map((action, k) => {
        return (<Button size="small" key={k} onClick={() => onActionSelected(action.type)}> <Iconify icon={action.icon} width={12} height={12} sx={{marginRight: '5px'}} /> {action.label}</Button>)
    });

    const rightActionButtons = actions.filter(i => i.placement == 'IN_RIGHT_MENU').map((action, k) => {
        return (<Button size="small" key={k} onClick={() => onActionSelected(action.type)}> <Iconify icon={action.icon} width={12} height={12} sx={{marginRight: '5px'}} /> {action.label}</Button>)
    });

    const leftActionButtonsWithWrapper = (() => {
        if(leftActionButtons.length == 0) {
            return null;
        }

        return (
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                {leftActionButtons}
            </ButtonGroup>
        )
    })()
    const rightActionButtonsWithWrapper = (() => {
        if(rightActionButtons.length == 0) {
            return null;
        }

        return (
            <ButtonGroup variant="outlined" aria-label="outlined button group">
                {rightActionButtons}
            </ButtonGroup>
        )
    })()
    return <GridToolbarContainer >
        <Box>
            {leftActionButtonsWithWrapper}
            {toolbar?.quickSearch?<GridToolbarQuickFilter />:null}
            {toolbar?.columns?<GridToolbarColumnsButton />:null}
            {toolbar?.filters?<GridToolbarFilterButton />:null}
        </Box>
        <RightHandedBox>
            {rightActionButtonsWithWrapper}
        </RightHandedBox>
    </GridToolbarContainer>
}

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
    border: '1px solid #fff',
    borderColor: alpha(theme.palette.grey[700], 0.2),
    marginLeft: 5,
    marginRight: 5,
    ['.MuiDataGrid-cell']: {
        whiteSpace: 'normal !important',
        wordWrap: 'break-word !important'
    },
    ['.MuiDataGrid-columnHeaders']: {
        borderBottom: '1px solid #fff',
        borderColor: alpha(theme.palette.grey[700], 0.2),
    },
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: theme.palette.grey[200],
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '&.Mui-selected': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity,
        ),
        '&:hover, &.Mui-hovered': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY +
              theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity,
          ),
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: alpha(
              theme.palette.primary.main,
              ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
          },
        },
      },
    },
    
  }));

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

function replaceInObject(object, replacement, parentKey = '') {
    if(typeof object !== "object")
        return object;
    if(object == null)
        return object;

    object = JSON.parse(JSON.stringify(object));
    replacement = JSON.parse(JSON.stringify(replacement))

    Object.keys(object).map(key => {
        if(typeof object[key] == "string") {
            Object.keys(replacement).map(replacementKey => {
                object[key] = object[key].replace(':'+parentKey+replacementKey+':', replacement[replacementKey])
            })
        }
        
        if(typeof object[key] == "object") {
            object[key] = replaceInObject(object[key], replacement, parentKey)
        }
    });

    return object;
}

const TableView = forwardRef(({}, ref) => {
    const timer = useRef(null);
    const {getCustomComponentByKey} = useContext(ComponentRepositoryContext);
    const {
        data,
        specialRowActions,
        description: { 
            name: resource,
            listing: {
                toolbar, columns, actions, options, batchActions
            }
        },
        reloadTimeout,
        noLoadingState,
        onRefreshFn
    } = useContext(ResourceContext);
    const { pathname, search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const {dispatchEvent: dispatchDaragridEvent} = useContext(DataGridEventsContext);

    const [datatableIsLoading, setDatatableIsLoading] = useState(false)

    const {isLoading, setFilters, setSorting, setPage, setLimit, totalRecords, page, limit, data: rows, refresh} = useListingQuery({url: `${resource}/list`, noLoadingState, onRefresh: onRefreshFn, data: {
        ...data,
        ...(() => {
            if(searchParams.has('tblvw')) {
                return JSON.parse(searchParams.get('tblvw'));
            }

            return {};
        })()
    }});

    const isMobile = useResponsive('down', 'sm');

    useImperativeHandle(ref, () => ({
        refreshDataTable() {
          refresh()
        }
      }));
    
    const [activeAction, setActiveAction] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [selectionModel, setSelectionModel] = useState([]);

    useEffect(() => {

        if(reloadTimeout == null)
            return;

        timer.current = setTimeout(refresh, reloadTimeout);
        return () => clearTimeout(timer.current)
    })

    const hasBatchActions = batchActions && batchActions.length > 0;

    const dataGridOptions = {
        data: {rowLength: 5, maxColumns: 6},
        components: {
            Toolbar: CustomToolbar
        },
        componentsProps: {
            toolbar: {
                actions,
                toolbar,
                onActionSelected: (actionType) => setActiveAction(actionType)
            }
        },
        columns: null,
        loading: isLoading || datatableIsLoading,
        rowsPerPageOptions: [5,25,100],
        pageSize: limit,
        autoHeight: true,
        paginationMode: 'server',
        rowCount: totalRecords,
        filterMode: 'server',
        sortingMode: "server",
        onSortModelChange: (e) => {setSorting(e)},
        onPageChange: (page) => {setPage(page)},
        onPageSizeChange: (newPageSize) => {setLimit(newPageSize)},
        onFilterModelChange: (e) => {setFilters(e)},
        rows,
        checkboxSelection: hasBatchActions,
        onSelectionModelChange: (newSelectionModel) => {
            setSelectionModel(newSelectionModel);
        },
        selectionModel: selectionModel,
        ...options
    };

    dataGridOptions.columns = dataGridOptions.columns ?? columns.map(i => {
        if(Array.isArray(i) || typeof i == "object") {
            return i;
        }
        return {field: i};
    });

    dataGridOptions.columns = dataGridOptions.columns.map(i => ({
        ...(options[i.field]?.hasOwnProperty('width')?{}:{flex: 1}),
        ...i
    }));

    dataGridOptions.columns = dataGridOptions.columns.map(i => {

        let newValue = {
            ...i
        };

        if(options.hasOwnProperty(i.field) && options[i.field].hasOwnProperty('cellRenderer')) {

            newValue = {
                ...newValue,
                ...options[i.field],
                renderCell: (params) => {
                    const Comp = CellRenderer(options[i.field].cellRenderer);

                    return <Comp {...params} />;
                }
            }

            /*switch(options[i.field].cellRenderer) {
                case 'image':
                    newValue = {
                        ...newValue,
                        ...options[i.field],
                        renderCell: (params) => <img src={params.value} style={{width: 50, height: 50, borderRadius: '100%'}} />
                    };
                break;
            }*/
        }

        if(options.hasOwnProperty(i.field) && options[i.field].hasOwnProperty('noWrap')) {
            newValue = {
                ...newValue,
                cellClassName: (params) => {
                    return 'no-wrap'
                }
            }
        }

        if(options.hasOwnProperty(i.field)) {
            newValue = {
                ...newValue,
                ...options[i.field]
            }
        }

        return newValue;
    })

    if((actions.length > 0) || (specialRowActions.length > 0)) {

        function getActions(params) {

            if(specialRowActions.length > 0) {

                return specialRowActions.map(action => {

                    return <GridActionsCellItem
                        showInMenu={action?.showInMenu}
                        component={action?.component} 
                        icon={<Iconify icon={action.icon} />}
                        label={action.label}
                        onClick={() => {
                            action.onClick(params.row)
                        }}
                    />
                });
            }

            const preActions = actions.filter(i => i.placement == 'IN_ROW').filter(action => {
                if(params.row.hasOwnProperty('__actions')) {
                    if(params.row.__actions.indexOf(action.type) === -1)
                        return false;
                }
                return true;
            }).map(action => {
                const CustomActionComponent = React.forwardRef((props, ref) => {
                    if(action?.component) {
    
                        if(action.component.hasOwnProperty('label')) {
                            /* custom button type */
                            /*
                            MUI: The `component` prop provided to ButtonBase is invalid.
                            Please make sure the children prop is rendered in this custom component. 

                            means that i should have a {children} part in Button
                            */
                            
                            return <Button onClick={props.onClick} size="small" variant="outlined" ref={ref}>{props.children}{action.component.label}</Button>;
                        }
    
                        return <ButtonBase {...props} ref={ref} />;
                    }
                    
                    return  <ButtonBase {...props} ref={ref} />;
                });

                return <GridActionsCellItem
                    showInMenu={action?.showInMenu} 
                    component={CustomActionComponent} 
                    icon={action.icon?<Iconify icon={action.icon} />:null}
                    label={`${action.label} (${params.id})`}
                    onClick={() => {
                        let eventKey = action?.event;
                        
                        if(eventKey) {
                            
                            dispatchDaragridEvent(eventKey.event, [params.row, action, resource, {...(Array.isArray(data)?data:new Array()), ...replaceInObject(eventKey.params, {'resource_id': params.id, 'resource': resource}), selectionModel }], () => {
                                refresh();
                                setDatatableIsLoading(false)
                            }, [datatableIsLoading, setDatatableIsLoading]);
                            return;
                        }

                        setSelectedRowId(params.id)
                        setActiveAction(action.type);
                    }}
                />
            });

            return preActions
        }

        dataGridOptions.columns =  [
            ...dataGridOptions.columns,
            {
                field: 'actions',
                type: 'actions',
                flex: 1,
                maxWidth: 150,
                getActions
            }
        ]
    }

    const actionFragment = (() => {
        if(activeAction == null)
            return;

        let action = actions.filter(i => i.type == activeAction)[0];
        let viewKey = action?.view;
        let linkKey = action?.link;
        let outerLinkKey = action?.outerLink;
        let eventKey = action?.event;


        let Comp = viewKey?getCustomComponentByKey(viewKey.component):null;

        const componentData = {
            resource,
            data,
            resourceId: selectedRowId,
            rows,
            row: (rows.filter(i => i.id == selectedRowId).length > 0)?rows.filter(i => i.id == selectedRowId).pop():{},
            handleClose: () => setActiveAction(null),
            handleTableViewRefresh: () => refresh(),
            ...(() => {
                if(viewKey) {
                    if(viewKey.hasOwnProperty('props')) {
                        const props = viewKey.props;
                        return replaceInObject(props, {'resource_id': selectedRowId, 'resource': resource})
                    }
                }
                return {};
            })()
        }

        if(linkKey) {
            return (
                <Navigate
                    to={{
                        pathname: replaceInObject({link: linkKey}, {'resource_id': selectedRowId, 'resource': resource}).link
                    }}
                />
            )
        }
        if(outerLinkKey) {
            window.location = replaceInObject({link: outerLinkKey}, {'resource_id': selectedRowId, 'resource': resource}).link
            return;
        }

        if(viewKey) {

            if(viewKey.container.indexOf('DRAWER') === 0) {
                let drawerWidth = '80vw';
                if(viewKey.hasOwnProperty('drawerWidth')) {
                    drawerWidth = viewKey.drawerWidth+'vw';
                }

                if(isMobile) {
                    drawerWidth = '100vw';
                }
                
                return (
                    <React.Fragment>
                        <Drawer
                            anchor={viewKey.container.split(':')[1].toLowerCase()}
                            open={true}
                            onClose={() => setActiveAction(null)}
                            sx={{
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}
                        >
                            {isMobile && (<AppBar position="sticky">
                            <Container maxWidth="xl">
                                <Toolbar disableGutters>


                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                                </Box>

                                <Box sx={{ flexGrow: 0 }}>
                                    <Tooltip title="Open settings">
                                    <IconButton onClick={() => setActiveAction(null)} sx={{ p: 0 }}>
                                        X
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                </Toolbar>
                            </Container>
                            </AppBar>)}
                            <AppErrorAlert errorMessageKey={`RESOURCE_BEFORE_COMP`} />
                            
                            <Comp {...componentData} />
                        </Drawer>
                    </React.Fragment> 
                )
            }

            if(viewKey.container.indexOf('DIALOG') === 0) {
                const dialogProps = viewKey.props && viewKey.props.hasOwnProperty('dialog_props')?viewKey.props.dialog_props:{};
                return (
                    <React.Fragment>
                        <Dialog open={true} onClose={() => setActiveAction(null)} {...dialogProps}>
                            <AppErrorAlert errorMessageKey={`RESOURCE_BEFORE_COMP`} />
                            <Comp {...componentData} />
                        </Dialog>
                    </React.Fragment> 
                )
            }

        }
        
        if(eventKey) {
                            
            dispatchDaragridEvent(eventKey.event, [null, action, resource, {...(Array.isArray(data)?data:new Array()), ...replaceInObject(eventKey.params, {'resource_id': null, 'resource': resource}), selectionModel }], () => {
                refresh();
            }, [datatableIsLoading, setDatatableIsLoading]);
            setActiveAction(null);
            return;
        }

        if(Comp) {
            return (
                <Modal
                    open={true}
                    onClose={() => setActiveAction(null)}
                    sx={{
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '80vw' },
                    }}
                >
                    <Box sx={{ ...modalStyle, width: 400 }}>
                        <AppErrorAlert errorMessageKey={`RESOURCE_BEFORE_COMP`} />
                        <Comp {...componentData}  />
                    </Box>
                    
                    
                </Modal>
            )
        }

        return null;
    })();

    return <>
            
            <StripedDataGrid
                {...dataGridOptions}
                getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  }
                  getRowHeight={() => 'auto'}
            />
        {actionFragment}
    </>
})

export default TableView;