import { Box, Button, FormControl, FormHelperText } from "@mui/material";
import useFormData from 'src/hooks/useFormData';

import { styled } from '@mui/material/styles';
import { useRef, useState } from "react";

const PreviewWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flex: 1,
    justifyContent: 'space-between',
    borderRadius: 50,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[500],
    '>.MuiButton-root': {
        borderTopRightRadius: 50,
        borderBottomRightRadius: 50
    }
}));

const Input = styled('input')({
    display: 'none',
});

function ImagePreview({url,onClear}) {
    return (
        <PreviewWrapper>
            <img src={url} style={{width: 50, height: 50, borderRadius: 50}} />
            <Button onClick={onClear} variant="contained" component="span">
                X
            </Button>
        </PreviewWrapper>
    )
}

function FilePreview({name,onClear}) {
    return (
        <PreviewWrapper>
            <span>{name}</span>
            <Button onClick={onClear} variant="contained" component="span">
                X
            </Button>
        </PreviewWrapper>
    )
}

export default function FileField({label, name}) {
    
    const {formData, setValue, getValue, getMessages, onValueChanged} = useFormData();
    //const [previewData, setImagePreviewData] = useState(null);
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    const [choosenFile, setChoosenFile] = useState(null);

    function clearInput() {
        setValue(name, null);
        setChoosenFile(null);
    }

    //const hasValue = ((getValue(name) != null) && (getValue(name).length > 0)) || previewData;
    //const value = (hasValue?( previewData?previewData:getValue(name) ):null);
    
    if(choosenFile) {
        /* there is a new choosen file */
        
        if (validImageTypes.includes(choosenFile.file['type'])) {
            return (<ImagePreview url={choosenFile.imagePreview} onClear={clearInput} />)
        }

        return (<FilePreview name={choosenFile.file.name} onClear={clearInput} />)
    }

    if((getValue(name) != null) && (getValue(name).length > 0)) {
        /* there is a pre-stored data, if a file input has data its most likely an image */
        return (<ImagePreview url={getValue(name)} onClear={clearInput} />)
    }

    /*if(hasValue) {
        return (
            <PreviewWrapper>
                <img src={value} style={{width: 50, height: 50, borderRadius: 50}} />
                <Button onClick={() => {
                    setImagePreviewData(null);
                    setValue(name, null);
                }} variant="contained" component="span">
                    X
                </Button>
            </PreviewWrapper>
        )
    }*/
    return (

        <FormControl error={getMessages(name).length > 0}>
            <label htmlFor={name+'-id'}>
                <Input accept="*/*" id={name+'-id'} multiple type="file" name={name} onChange={(e) => {
                    setChoosenFile({
                        file: e.target.files[0],
                        imagePreview: URL.createObjectURL(e.target.files[0])
                    })
                    //setImagePreviewData(URL.createObjectURL(e.target.files[0]));
                    onValueChanged(e);
                }} />
                <Button variant="contained" component="span">
                    Fájl kiválasztása
                </Button>
            </label>
            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </FormControl>
    )
}