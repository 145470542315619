import { Avatar, Chip, Divider, Box, List, Card, CardContent, Button, Paper, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, ListSubheader, Stack, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import useSuperFetch from "src/hooks/useSuperFetch";
import usePackingContext from "./usePackingContext";
import { alpha, styled } from '@mui/material/styles';

import ResourceSelector from "../ResourceSelector";
import Resource from "src/vendor/Resource";
import ProductsInfoTab from "./Tabs/ProductsInfoTab";

const InfoCard = styled(Card)(({ theme }) => ({
    border: '1px solid #fff',
    borderColor: alpha(theme.palette.grey[700], 0.2)
}))

const StyledStack = styled(Stack)(({ theme }) => ({

}))

const Item = styled(Paper)(({ theme }) => ({

        marginLeft: 5,
        marginRight: 5

}))

export default function() {
    const {products, locationId, currentOrder, showOnlyOrderProducts, actionCleanupRemainingProducts, actionAddProductToParcel, refreshProducts, picklistId, dispatch, shipment} = usePackingContext();

    if(products.filter(i => parseInt(i.tote_stock) > 0).length > 0) {
        return (
            <Box elevation={2} sx={{width: '100%'}}>
                <StyledStack  spacing={2}>
                    <Item>
                        <InfoCard>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                Termékek visszatárolása
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                
                                </Typography>
                                

                                <ResourceSelector resource={`locations`} currentValue={locationId} onSelected={(code) => {
                                    if(code.indexOf("LOC-") > -1) {
                                        dispatch({type: 'SET_CURRENT_LOCATION', payload: code.substring(4)})
                                    } else {
                                        dispatch({type: 'SET_CURRENT_LOCATION', payload: code})
                                    }
                                }} onSelectionRemoved={() => dispatch({type: 'SET_CURRENT_LOCATION', payload: null})} />
                                
                                {locationId != null?<Button sx={{width: '100%'}} size="small" variant="contained" color="success" onClick={() => actionCleanupRemainingProducts()}>Kicsomagol</Button>:null}
                                
                            </CardContent>
                        </InfoCard>
                    </Item>
                    <hr/>
                    <ProductsInfoTab />
                    <hr/>
                    <Item>
                        <Button sx={{width: '100%'}} size="small" variant="contained" color="error" onClick={() => window.location = '/app/picking/list'}>Mégsem</Button>
                    </Item>
                    

                
                </StyledStack>
            </Box>
        )
    }

    return <p>Minden kész</p>
    
}