import { Navigate, Route } from "react-router-dom";
import useAppState from "src/hooks/useAppState";

export default function({element}) {

    const {tokenId} = useAppState();

    if (!tokenId || tokenId.length < 10) {
        return <Navigate to="/login" replace />;
    }

    

    return element;
}