import { Avatar, Chip, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, ListSubheader, Stack, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import useSuperFetch from "src/hooks/useSuperFetch";
import StyledElements from "../StyledElements";
import usePackingContext from "../usePackingContext";

export default function({showOrderOnlySwitch = true}) {
    const {products, currentOrder, showOnlyOrderProducts, actionAddProductToParcel, refreshProducts, picklistId, dispatch, shipment} = usePackingContext();


    const totes = [...new Set(products.map(i => i.tote_id))].map(toteId => {
        return {
            tote_id: products.filter(i => i.tote_id == toteId)[0].tote_id,
            tote_name: products.filter(i => i.tote_id == toteId)[0].tote_name,
            products: products.filter(i => i.tote_id == toteId).filter(productI => {
                return true;
            })
        }
    })


    return (
    <>
        <List
            disablePadding 
            subheader={
                <ListSubheader disablePadding>
                    <Stack direction="row">
                        <div style={{display: 'flex', flex: 1}}>Termékek</div>
                        
                    </Stack>

                </ListSubheader>
            }
            sx={{
                maxHeight: 'calc(100vh - 88px - 80px - 80px)',
                overflowY: 'auto',
                overflowX: 'hidden',
                '.MuiListItem-container': {
                    display: 'flex'
                }
            }}
        >
            {totes.map((toteI, toteK) => {


                return (
                <React.Fragment key={toteK}>
                    <ListSubheader disablePadding>{toteI.tote_name}</ListSubheader>
                    {toteI.products.map((productI, productK) => {


                        return (
                        <React.Fragment>
                        <ListItem 
                            key={productK} 
                            sx={{marginLeft: '15px'}}
                        >
                            <ListItemAvatar>
                                <Avatar src={productI.product_image_url} />
                            </ListItemAvatar>
                            <ListItemText primaryTypographyProps={{ style: { wordWrap: 'break-word', whiteSpace: "normal" } }}>{productI.product_title}</ListItemText>
                            <ListItemSecondaryAction sx={{
                                position: 'relative',
                                transform: 'none',
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                                <Stack direction={`row`} spacing={1}>
                                    <Chip color='primary' label={`${productI.tote_stock} db`} />
                                </Stack>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        </React.Fragment>
                        )
                    })}
                </React.Fragment>
                )
            })}
        </List>
    </>
    );
}