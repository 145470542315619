import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef, useEffect } from 'react';
import { Box, Breadcrumbs, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Link from '@mui/material/Link';
import useAppState from 'src/hooks/useAppState';

const PageWrapper = styled(Box)((theme) => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column'
}))

const HeaderWrapper = styled(Box)((theme) => ({
  padding: "5px",
  border: "1px solid d9d9d9",
  borderRadius: "5px",
  background: "#f9f9f9",
  margin: "6px"
}))

const ContentWrapper = styled(Paper)((theme) => ({
  padding: 15,
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  width: '100%'
}))


const Page = forwardRef(({ children, title = '', breadcrumbs = [], headerComponent, ...other }, ref) => {

  const {title: oldTitle, dispatch} = useAppState();

  useEffect(() => {
    if(title != oldTitle)
      dispatch({type: 'SET_TITLE', payload: title})

  })

  


  const HeaderComponent = (() => {
    if(headerComponent) 
      return headerComponent;

    const items = [
      <Typography key="3" color="text.primary">ShipNow</Typography>,
      ...(() => {
        return breadcrumbs.map(i => (
          <Link
          component={RouterLink}
          underline="hover"
          key="2"
          color="inherit"
          to={i[0]}
        >
          {i[1]}
        </Link>
        ))
      })(),
      <Typography key="3" color="text.primary">{ title }</Typography>
    ]

    return (
      <Breadcrumbs separator="›" aria-label="breadcrumb">{items}</Breadcrumbs>
    )

  })()

  return (
  <PageWrapper ref={ref} {...other}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <HeaderWrapper>
      {HeaderComponent}
    </HeaderWrapper>
    <ContentWrapper elevation={3}>
      {children}
    </ContentWrapper>
  </PageWrapper>)
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;