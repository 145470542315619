import useFormData from "src/hooks/useFormData";
import ReCAPTCHA from "react-google-recaptcha";
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
export default function Recaptcha({name}) {
    const {formData, getMessages, setFormData} = useFormData();

    const onChange = (value) => {
        setFormData({
            ...formData,
            values: {
                ...formData.values,
                [name]: value
            }
        })
    }
    return (
        
        <FormControl error={getMessages(name).length > 0}>
            <ReCAPTCHA
                sitekey={`${process.env.REACT_APP_RECAPTCHA_KEY}`}
                onChange={onChange}
            />
            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </FormControl>
    )
}