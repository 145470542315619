import { Chip, List, Box, ListItem, ListItemButton, ListItemText, ListSubheader, Stack, Switch, Typography, Button, ButtonGroup } from "@mui/material";
import React, { useEffect } from "react";
import useSuperFetch from "src/hooks/useSuperFetch";
import StyledElements from "../StyledElements";
import usePackingContext from "../usePackingContext";
import ParcelsView from "./Shipment/ParcelsView";

export default function() {
    const {currentOrderId} = usePackingContext();
    const {get} = useSuperFetch()

    
    return (
    <StyledElements.PackingWrapper>

        <Stack direction='column' sx={{height: '100%'}}>
            <StyledElements.SubheaderClone>Megrendelés</StyledElements.SubheaderClone>
            {/*<Box>
                <Stack direction='row'>
                    <Box>Címzett</Box>
                    <Box>Szállítási mód</Box>
                </Stack>
            </Box>
            <Box>
                Kiemelt utasítások, currentOrderId: {currentOrderId}
            </Box>*/}
            <Box>
                CurrentOrderId: {currentOrderId}<br/>
            </Box>
            <Box sx={{flex: 1}}>
                <ParcelsView  />
            </Box>
            <Box>
                <ButtonGroup>
                </ButtonGroup>
            </Box>
        </Stack>
    </StyledElements.PackingWrapper>
    );
}