import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import useFormData from 'src/hooks/useFormData';
import { styled } from '@mui/material/styles';
import AutocompleteTextField from './AutocompleteTextField';
import Resource from 'src/vendor/Resource';
import { Box } from '@mui/material';

const StyledFormControl = styled(Box)(({theme}) =>({
    width: '100%'
}));

export default function ResourceTable(opts) {
    const {
        label,
        name,
        type = 'text',
        resource,
        data,
        resourceId
    } = opts;
    const {formData, getValue, getMessages, onValueChanged} = useFormData();

    const resourceData = (() => {
        if(typeof data != "object")
            return {};
        const props = data;

        Object.keys(props).map(key => {
            if(typeof props[key] == "string") {
                props[key] = props[key].replace(':resource_id:', resourceId).replace(":resource:", resource)
            }
        });
        
        return props;
    })()

    return (
        <StyledFormControl error={getMessages(name).length > 0}>
            <Resource name={resource} data={resourceData} />
        </StyledFormControl>
    )
}