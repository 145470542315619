import { useLocation } from "react-router-dom";
import { Paper } from "@mui/material";
import Page from "src/components/Page";
import Resource from "src/vendor/Resource";
export default function() {
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);

    return <Page title={params.get('page_title')}>

            <div style={{ width: '100%' }}>
                <Resource name={params.get('resource')} />
            </div>
    </Page>
}