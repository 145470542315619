import { Navigate, useLocation } from "react-router-dom";
import Page from "src/components/Page";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useState } from "react";
import { IconButton, Paper, Stack } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import QuickMoveComp from "src/components/Custom/Putaway/QuickMove";
import useSuperFetch from "src/hooks/useSuperFetch";

const breadcrumbs = [
    ['/app/putaway/list', 'Betároló listák']
];


export default function QuickMove() {
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);
    const [value, setValue] = useState(-1);
    const {post} = useSuperFetch();

    if(value == 0) {
        /*return <Navigate to={`/app/putaway/list`} />*/
    }
    if(value == 1) {
        /*return <Navigate to={`/app/putaway/progress?putawaylist_id=${params.get('putawaylist_id')}`} />*/
    }

    async function onChangeValue(value) {
        if(value == 1) {
            await post(`putawaylists/set_status`, {
                putawaylist_id: params.get('putawaylist_id'),
                status_id: 3
            });
        }

        setValue(value);
    }

    return <React.Fragment>
        
        <Page breadcrumbs={breadcrumbs} title={'Gyors mozgatás'}>

                <QuickMoveComp  />
        </Page>
        
        {/*<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    onChangeValue(newValue);
                }}
            >
                <BottomNavigationAction label="Mégsem" icon={<CancelIcon />} />
                <BottomNavigationAction label="Tovább" icon={<NavigateNextIcon />} />
            </BottomNavigation>
            </Paper>*/}
    </React.Fragment>
}