import { Navigate, useLocation } from "react-router-dom";
import Page from "src/components/Page";

import React, { useState } from "react";
import PackingView from "src/components/Custom/Packing/PackingView";


const breadcrumbs = [
    ['/app/picking/list', 'Szedőlisták']
];


export default function Packing() {
    const { pathname, search } = useLocation();
    const params = new URLSearchParams(search);

    
    return <React.Fragment>
        
        <Page breadcrumbs={breadcrumbs} title={'Csomagolás'}>
            <PackingView picklistId={params.get('picklist_id')}/>  
        </Page>

    </React.Fragment>
}