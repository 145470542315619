import { Paper,CircularProgress, Card,Switch, CardContent, Button, CardActions, Typography, Stack, ButtonGroup } from "@mui/material";
import { Box } from "@mui/system";
import { clone } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";

import { alpha, styled } from '@mui/material/styles';


import BarcodeReader from 'react-barcode-reader';
import AppErrorAlert from "src/components/AppErrorAlert";
import AppContext from "src/contexts/app/context";
import useSuperFetch from "src/hooks/useSuperFetch";
import Resource from "src/vendor/Resource";
import ResourceSelector from "../ResourceSelector";
import AppMessages from "src/components/AppMessages";

const InfoCard = styled(Card)(({ theme }) => ({
    border: '1px solid #fff',
    borderColor: alpha(theme.palette.grey[700], 0.2)
}))

const StyledStack = styled(Stack)(({ theme }) => ({

}))

const Item = styled(Paper)(({ theme }) => ({

        marginLeft: 5,
        marginRight: 5

}))

let _processTimer;
function EasyIntake({deliveryId}) {
    const [scanQueue, setScanQueue] = useState([]);
    const [displayAllIntakeProducts, setDisplayAllIntakeProducts] = useState(false);
    const [displayFinishedIntakeProducts, setDisplayFinishedIntakeProducts] = useState(false);
    const [displayRemainingIntakeProducts, setDisplayRemainingIntakeProducts] = useState(false);
    const [currentToteLocationId, setCurrentToteLocationId] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isPreview, setIsPreview] = useState(0);

    const superFetch = useSuperFetch();

    function onScan(scan) {

        if(scan.indexOf("LOC-") === 0) {
            setCurrentToteLocationId(scan.split("-")[1]);
            return;
        }
        setScanQueue([...scanQueue, scan]);
    }

    async function doUpload(scans) {
        if(isUploading)
            return;
        setIsUploading(true);
        let resp = await superFetch.post("deliveries/intake", {delivery_id: deliveryId, is_preview: isPreview, tote_location_id: currentToteLocationId, scans});

        if(resp == null) {
            setScanQueue([...scans])
        }
        setIsUploading(false);
        //setIsPreview(false);
    }

    useEffect(() => {
        if(scanQueue.length == 0)
            return;

        let timeoutInterval = isPreview?1:400;

        _processTimer = setTimeout(() => {

            let c = clone(scanQueue);
            setScanQueue([]);
            
            doUpload(c)
        }, timeoutInterval)
        return () => clearTimeout(_processTimer);
    })


    async function doManualIntake() {
        if(isUploading)
            return;
        setIsUploading(true);
        const barcode = prompt("Termék vonalkódja?");
        const quantity = prompt("Darabszám");

        let resp = await superFetch.post("deliveries/intake", {delivery_id: deliveryId, is_preview: isPreview, tote_location_id: currentToteLocationId, scans: Array.apply(null, Array(parseInt(quantity || 0))).map(function(){return barcode})});
        setIsUploading(false);
        //setIsPreview(false);
    }

    return (
        <Box elevation={2} sx={{width: '100%'}}>
            <BarcodeReader onScan={onScan} minLength={5} />
            <StyledStack  spacing={2}>
                <Item>
                    <InfoCard>
                        <CardContent>
                            <StyledStack  spacing={2}>
                                <StyledStack direction={'row'} spacing={2}>
                                    <Typography sx={{flex: 1}} gutterBottom variant="h5" component="div">{isPreview?'Lekérdezés':'Tárolás'}</Typography>
                                    <div style={{display: 'flex'}}><Switch key={`switch-${isPreview}`} label={`Információ`} onChange={() => setIsPreview((i) => i==0?1:0)} checked={isPreview} /></div>
                                </StyledStack>
                                <ResourceSelector resource={`locations`} currentValue={currentToteLocationId} onSelected={setCurrentToteLocationId} onSelectionRemoved={() => setCurrentToteLocationId(null)} />
                                {isUploading?<CircularProgress/>:null}
                            </StyledStack>
                        </CardContent>
                        {(isUploading == false)?
                        <CardActions>
                            <ButtonGroup>
                                <Button size="small" variant="contained" color={displayAllIntakeProducts?"success":"primary"} onClick={() => setDisplayAllIntakeProducts(!displayAllIntakeProducts)}>Teljes lista mutat</Button>
                                <Button size="small" variant="contained" color={displayFinishedIntakeProducts?"success":"primary"} onClick={() => setDisplayFinishedIntakeProducts(!displayFinishedIntakeProducts)}>Kész lista mutat</Button>
                                <Button size="small" variant="contained" color={displayRemainingIntakeProducts?"success":"primary"} onClick={() => setDisplayRemainingIntakeProducts(!displayRemainingIntakeProducts)}>Fennmaradó lista mutat</Button>

                            </ButtonGroup>
                            
                            <ButtonGroup>
                                <Button size="small" variant="contained" color={"primary"} onClick={() => doManualIntake()}>Kézi bevételezés</Button>
                            </ButtonGroup>
                        </CardActions>
                        :null}
                    </InfoCard>
                </Item>
                <Item>
                    {/*<AppErrorAlert errorMessageKey={'INTAKE_ERRORS'} />*/}
                    <AppMessages />
                </Item>
                
                {displayAllIntakeProducts && (<Item>
                    <Resource name={`delivery_intake_products`} data={{tote_location_id: currentToteLocationId, delivery_id: deliveryId}} />
                </Item>) }
                
                {displayFinishedIntakeProducts && (<Item>
                    <Resource name={`delivery_intake_products`} data={{tote_location_id: currentToteLocationId, delivery_id: deliveryId, is_finished: 1}} />
                </Item>) }
                
                {displayRemainingIntakeProducts && (<Item>
                    <Resource name={`delivery_intake_products`} data={{tote_location_id: currentToteLocationId, delivery_id: deliveryId, is_finished: 0}} />
                </Item>) }
               
            </StyledStack>
        </Box>

    )
}

export default  EasyIntake;