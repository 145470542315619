import useResourceSelector from "./useResourceSelector";
import CardContent from '@mui/material/CardContent';
import React, { useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { Avatar } from "@mui/material";
import useSuperFetch from "src/hooks/useSuperFetch";

export default function() {

    const {currentValue, resource} = useResourceSelector();
    const superFetch = useSuperFetch();
    const [resourceData, setResourceData] = useState(null);

    useEffect(() => {
        if(isNaN(parseInt(currentValue))) {
            if(resourceData != null) {
                setResourceData(null);
            }
            return;
        }

        (async () => {
            const r = await superFetch.get(`${resource}/view/${currentValue}`);
            setResourceData(r.data);
        })()
    }, [currentValue])

    if(resourceData == null) {
        return (
            <Avatar>?</Avatar>
        )
    }

    if(resourceData.image) {
        return <img src={resourceData.image} style={{maxWidth: '50px', borderRadius: '50px'}}/>
    }

    if(resourceData.image_url) {
        return <img src={resourceData.image_url} style={{maxWidth: '50px', borderRadius: '50px'}} />
    }


    return (
        <Avatar>?</Avatar>
    )


}