import { useContext } from "react";
import AppContext from "src/contexts/app/context";

/*
    const superFetch = useSuperFetch();

    superFetch.get(url, data)
*/
export default function useSuperFetch() {
    const {superFetch} = useContext(AppContext);

    return superFetch;
}