import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useContext, useState } from 'react';
import { FileField, Form, SubmitButton } from 'src/vendor/FormX';


export default function ImportDialog({handleClose, resource, resourceId, handleTableViewRefresh}) {

    function onError() {

    }

    function onSuccess() {
        if(handleTableViewRefresh)
            handleTableViewRefresh();
        if(handleClose)    
            handleClose();
    }

    function onSubmit() {

    }

    const formAction = `${resource}/upload`;
    return (
        <React.Fragment>
            <DialogTitle>Import</DialogTitle>
            <DialogContent>
                <DialogContentText>Fájl feltöltése ide: { resource }</DialogContentText>
                <Form action={formAction} onSuccess={onSuccess} onError={onError} onSubmit={onSubmit} initialValues={{}}>
                    <FileField label={'Import fájl'} name={'file'} />
                    <SubmitButton label={'Rendben'} />
                </Form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Mégsem</Button>
            </DialogActions>
        </React.Fragment>
    )
}