import Page from "src/components/Page";
import {FileField, Recaptcha, FormGroup, Form, SubmitButton, TextField, Checkbox} from "src/vendor/FormX";
import {Stack} from '@mui/material'
import SimpleLink from "src/components/SimpleLink";
import { alpha, styled } from '@mui/material/styles';

export default function Signup() {

    const Wrapper = styled(Page)( (theme) => ({
        width: '100%',
    }) );

    return <Wrapper title={`Regisztráció`}>
        <SimpleLink to="/login">{'< Belépés'}</SimpleLink>
        <Form action='authentication/signup'>
            <FormGroup label='Új felhasználó'>
                <Stack direction="column" spacing={2}>
                    
                    <Stack alignItems='stretch' justifyContent='space-between' direction="row" spacing={2}>
                        <TextField label={'Vezetéknév'} name={'lastname'} />
                        <TextField label={'Keresztnév'} name={'firstname'} />
                    </Stack>
                    <TextField label={'Email'} name={'email'} />
                    <TextField label={'Jelszó'} name={'password'} type={'password'} />
                    <Checkbox label={'Elfogadom az általános szerződési feltételeket'} name={'tos'} />
                    <Recaptcha name={'recaptcha'} />
                    <SubmitButton label={'Küldés'} />
                </Stack>
            </FormGroup>
        </Form>
    </Wrapper>
}