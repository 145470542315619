import { Paper, Card, CardContent, Button, CardActions, Typography, Stack, Dialog } from "@mui/material";
import { Box } from "@mui/system";
import { clone } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { alpha, styled } from '@mui/material/styles';

import BarcodeReader from 'react-barcode-reader';
import useSuperFetch from "src/hooks/useSuperFetch";
import UnpackShipment from "src/components/Custom/Shipment/Unpack";
import ShipmentTab from "src/components/Custom/Packing/Tabs/ShipmentTab";

export default function UnpackView() {
    const {get} = useSuperFetch();
    const [currentShipment, setCurrentShipment] = useState(null);

    async function onScan(scan) {
        if(scan.indexOf("LOC-") !== 0) {
            /* so its not a tote */
            /* it must be a shipment parcel tracking number */
            const response = await get('shipments/list?filters[quickFilterValues][0]='+scan);
            if(response.queryResult && response.queryResult.rows.length == 1) {
                setCurrentShipment(response.queryResult.rows[0]);
            }


            //setCurrentToteLocationId(scan.split("-")[1]);
            return;
        }
    }

    function test() {
        onScan('CLFOX168741901690792');
    }


    function getDialog() {
        if(currentShipment == null) {
            return <p>Olvasd be a csomag kódját.</p>;
        }

        // <Dialog open={true} onClose={() => setActiveAction(null)} {...dialogProps}>

        return <Dialog open={true} onClose={() => setCurrentShipment(null)} >
            <UnpackShipment resourceId={currentShipment.id} row={currentShipment} />
        </Dialog>

    }

    return (
        <Box elevation={2}>
            <BarcodeReader onScan={onScan} minLength={5} />
            {getDialog()}
            <button onClick={test}>Test</button>
        </Box>

    )
}