import { Paper, Card, CardContent, Button, CardActions, Typography, Stack, Dialog } from "@mui/material";
import { Box } from "@mui/system";
import { clone } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { alpha, styled } from '@mui/material/styles';

import BarcodeReader from 'react-barcode-reader';
import useSuperFetch from "src/hooks/useSuperFetch";
export default function CycleCount() {
    const {get} = useSuperFetch();

    async function onScan(scan) {
        if(scan.indexOf("LOC-") === 0) {
            /* so its not a tote */
            /* it must be a shipment parcel tracking number */
            const response = await get('location_stocks/list?location_id='+scan.split('-')[1]);
            if(response.queryResult && response.queryResult.rows.length > 0) {
                console.log(response.queryResult);
            }


            return;
        }
    }

    function test() {
        onScan('LOC-10');
    }


    return (
        <Box elevation={2}>
            <BarcodeReader onScan={onScan} minLength={5} />

            <button onClick={test}>Test</button>
        </Box>

    )
}