import React, { useState } from "react";
import useListingQuery from "src/hooks/useListingQuery";

import BarcodeReader from 'react-barcode-reader';
import useSuperFetch from "src/hooks/useSuperFetch";
const { Box, Dialog, DialogTitle, DialogContent, CircularProgress, Card, CardHeader, CardContent, CardMedia, Badge, Button, Stack, Chip, ButtonGroup } = require("@mui/material");

function Item({barcodes, product_id, title, image_url, scanned, currentIndex, totalItems}) {
    

    return (
        <React.Fragment>
            <Card>
                <CardHeader
                    title={title}
                    subheader={totalItems+' / '+(currentIndex+1)}
                />
                <CardMedia
                    sx={{ margin: '0px auto', width: '200px', height: '200px' }}
                    image={image_url}
                    title={title}
                />
                <CardContent>
                    <Stack>
                        {barcodes.map((i) => <Chip size="small" color={i == scanned?'success':"primary"} label={i} />)}
                        {scanned != null && barcodes.indexOf(""+scanned) == -1?<Chip szie="small" color="success" label={scanned} />:<></>}
                    </Stack>
                </CardContent>
            </Card>

        </React.Fragment>
    )
}

function ItemChecker({items}) {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [scanned, setScanned] = useState(null);
    const {get} = useSuperFetch();

    function onScan(c) {
        setScanned(c);
    }

    async function onPrint() {
        const r = await get('delivery_intake_products/print_barcode?value='+items[currentIndex].quantity+'&product_id='+items[currentIndex].product_id);
        if(r?.messages && r.messages.length > 0)
            return;
        setScanned(null);
        setCurrentIndex((i) => i+1);
    }

    async function onAdd() {
        const r = await get('products/add_barcode?code='+scanned+'&product_id='+items[currentIndex].product_id);
        if(r?.messages && r.messages.length > 0)
            return;
        setScanned(null);
        setCurrentIndex((i) => i+1);
    }

    if(items.length == 0 || items.length <= currentIndex) {
        return (<p>Vége...</p>)
    }

    return (
        <div>

            <BarcodeReader onScan={onScan} minLength={5} />
            <Item scanned={scanned} currentIndex={currentIndex} totalItems={items.length} {...items[currentIndex]} />
            <Stack direction={'row'}>
                <ButtonGroup>
                    <Button onClick={() => onPrint()}>Nyomtatás</Button>
                    <Button disabled={scanned == null || items[currentIndex].barcodes.indexOf(scanned) > -1} onClick={() => onAdd()}>Hozzáad</Button>
                    <Button onClick={() => setCurrentIndex(i => i+1)}>Következő</Button>
                </ButtonGroup>

            </Stack>
            
        </div>
    )
}

function Checking({deliveryId}) {

    const {data: deliveryProducts, isLoading: deliveryProductsIsLoading, refresh: refreshDeliveryProducts} = useListingQuery({url: 'delivery_products/list', data: {delivery_id: deliveryId}});

    if(deliveryProductsIsLoading) {
        return <CircularProgress />
    }

    return <Box elevation={2}>
        <DialogTitle>Checking</DialogTitle>
        <DialogContent>
            <ItemChecker key={`checker`+deliveryId+deliveryProducts.length} items={deliveryProducts} />
        </DialogContent>
    </Box>
}

export default Checking;