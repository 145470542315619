import { useRef } from "react";
import Page from "src/components/Page";
import useSuperFetch from "src/hooks/useSuperFetch";
import Resource from "src/vendor/Resource";

import BarcodeReader from 'react-barcode-reader';
export default function PicklistList() {
    const putawaylistRef = useRef(null);
    const {post} = useSuperFetch();

    async function onScan(scan) {
        if(scan.indexOf("LOC-") === 0) {
            const r = await post("picklists/get_by_location", {location_id: scan.split("-")[1]});

            if(r.data.picklist_id) {
                window.location = '/app/packing?picklist_id='+r.data.picklist_id;
            }
            return;
        }
    }


    return <Page title={'Szedőlisták'}>
        <BarcodeReader onScan={onScan} minLength={5} />
        <Resource ref={putawaylistRef} name={`picklists`} data={{}} />
    </Page>
}