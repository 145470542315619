import React, { useState, useRef } from "react";
import BarcodeReader from 'react-barcode-reader';
import { Chip, CircularProgress, Stack, Divider, Dialog, DialogContent, Paper, Typography, Menu, Button, MenuItem, IconButton } from "@mui/material";
import useSuperFetch from "src/hooks/useSuperFetch";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Resource from "src/vendor/Resource";

import { styled } from '@mui/material/styles';
function CardHeaderOptions({secretMenuSetter, onTransferAll}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
      return true;
    };
  
    return (
      <div>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: 48 * 4.5,
              width: '20ch',
            },
          }}
        >
            <MenuItem onClick={() => handleClose() && secretMenuSetter('locations_dialog') }>Hely választása</MenuItem>
            <MenuItem onClick={() => handleClose() && onTransferAll() }>Mindet átmozgat</MenuItem>
        </Menu>
      </div>
    );
}
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function() {
    const {post,get} = useSuperFetch();
    const productsRef = useRef(null);
    const [secretMenu, setSecretMenu] = useState(null);
    const [locationA, setLocationA] = useState(null);
    const [locationB, setLocationB] = useState(null);
    const [stackSize, setStackSize] = useState(1);

    async function onScan(scan) {
        if(scan.indexOf('LOC-') == 0) {
            const locationId = scan.split('-')[1];

            if(locationA != null && locationB != null)
                return;

            const r = await get('locations/view/'+locationId);
            if(locationA == null) {
                setLocationA(r.data);
                return;
            }
            setLocationB(r.data);
            return;
        }

        await post('quick_move/transfer', {
            from_location_id: locationA.id,
            to_location_id: locationB.id,
            barcode: scan,
            quantity: stackSize
        });
        
        if(productsRef && productsRef.current) {
            productsRef.current.refreshDataTable();
        }
    }

    async function onTransferAll() {
        await post('quick_move/transfer_all', {
            from_location_id: locationA.id,
            to_location_id: locationB.id
        });
        
        if(productsRef && productsRef.current) {productsRef.current.refreshDataTable();}
    }

    function SelectButton({onClick}) {

        return <Button onClick={onClick} size="small" variant="outlined">kiválaszt</Button>;
    }

    const secretMenuContent = (() => {
        if(secretMenu == null)
            return null;

        if(secretMenu == 'locations_dialog') {
            return (<Dialog maxWidth={`lg`} fullWidth={true} open={true} onClose={() => setSecretMenu(null)}>
                <Resource data={{/*is_cart: 1*/}} name={`locations`} specialRowActions={[
                    {icon: 'fa-eye', label: 'Kijelölés', onClick: (row) => onScan('LOC-'+row.id) && setSecretMenu(null), component: SelectButton}
                ]} />
            </Dialog>)
        }
    })();

    return (<React.Fragment>


        <BarcodeReader  minLength={5} onScan={onScan} />
        <Stack direction={'column'} sx={{width: '100%'}}>
            <Card sx={{ width: '100%' }} raised={false}>
                <CardHeader
                    action={
                        <CardHeaderOptions
                            secretMenuSetter={setSecretMenu}
                            onTransferAll={onTransferAll}
                        />
                    }
                />
                <CardContent sx={{marginTop: '-64px', paddingRight: '100px'}}>

                    <Stack justifyContent="space-around" direction={'row'}>
                        <Item onClick={() => setStackSize((() => {
                            const sizes = [1,5,10,25,100];
                            const currentIndex = sizes.indexOf(stackSize);
                            if(sizes.length <= currentIndex+1) {
                                return 1;
                            }

                            return sizes[currentIndex+1];
                        })())}>
                            <Chip label={[stackSize, 'x'].join('')} />
                        </Item>
                        <Paper sx={{width: '100%'}}>
                        <Stack justifyContent="space-evenly" direction={'row'} sx={{width: '100%'}}>
                            <Item onClick={() => setLocationA(null)}>
                                <span>Honnan</span>
                                <Divider orientation="horizontal" flexItem />
                                <Chip label={locationA?locationA.name:'Kérlek válassz'} />
                            </Item>
                            <Item onClick={() => {const r = locationA; setLocationA(null); setLocationA(locationB); setLocationB(r); if(productsRef && productsRef.current) {productsRef.current.refreshDataTable();}}}>
                                <Chip label={'<->'} />
                            </Item>
                            <Item onClick={() => setLocationB(null)}>
                                <span>Hova</span>
                                <Divider orientation="horizontal" flexItem />
                                <Chip label={locationB?locationB.name:'Kérlek válassz'} />
                            </Item>
                        </Stack>
                        </Paper>
                    </Stack>
                </CardContent>
            </Card>
            
            {locationA != null?<Resource key={locationA?locationA.id:-1} ref={productsRef} name={`quick_move_location_products`} specialRowActions={[]} data={{location_id: locationA.id}} />:null}
        </Stack>
        {secretMenuContent}
    </React.Fragment>)
}