import React, { useState } from "react";
import useListingQuery from "src/hooks/useListingQuery";
import useSuperFetch from "src/hooks/useSuperFetch";
import Item from "./ProductList/Item";
import BarcodeReader from 'react-barcode-reader';
import List from "./ProductList/List";
import { Chip, Stack } from "@mui/material";

function useListingQueryWithMap(opts, mapFn) {

    const {data, refresh} = useListingQuery({...opts});
    
    return {data: data?.map(mapFn), refresh};
}

export default function({putawaylistId}) {
    const superFetch = useSuperFetch();

    const {data: productRows, refresh: refreshProducts} = useListingQueryWithMap({url: `putawaylist_location_products/list`, data: {
        putawaylist_id: putawaylistId
    }}, (item) => ({...item, uid: [item.location_id, item.product_id].join("/"), barcodes: item.product_barcodes.split(',').filter(i => i.length > 1)}));

    const {data: locationRows, refresh: refreshLocations} = useListingQuery({url: `putawaylist_locations/list`, data: {
        putawaylist_id: putawaylistId
    }});

    const [toteLocation, setToteLocation] = useState(null);
    const [scannedBarcode, setScannedBarcode] = useState(null);
    const [selectedUID, setSelectedUID] = useState(null);
    const [quantity, setQuantity] = useState(null);

    async function onScan(scan) {
        /*await superFetch.post("putawaylists/assign_location", {
            putawaylist_id: putawaylistId,
            location_id: scan
        });
        
        refreshProducts();
        refreshLocations();*/
        scan = scan.replace(/[\u0000-\u001F\u007F-\u009F]/g, "");
        if(scan.indexOf("LOC") === 0) {
            await superFetch.post("putawaylists/putaway", {
                uid: selectedUID,
                tote_location_id: scan,
                quantity
            });

            setScannedBarcode(null);
            setSelectedUID(null);
            setQuantity(null);

            refreshProducts();
            refreshLocations();

        } else {
            if(scan == scannedBarcode) {
                let maxQty = productRows.filter(i => i.uid = selectedUID).pop().quantity;
                if(quantity == maxQty)
                    return;

                setQuantity((e) => e+1);
            } else {
                setScannedBarcode(scan);
                setSelectedUID(productRows.filter(i => i.barcodes.indexOf(scan)>-1)?.pop().uid)
                setQuantity(1);
            }
            // if(scannedBarcode == scan) {
            //     /* if we scanned the same barcode twice, we are looking for the scan barcode in productRows but not the current UID */

            //     const list = productRows.filter(i => i.uid != selectedUID).filter(i => i.barcodes.indexOf(scan));

            //     if(list.length > 0) {
            //         setSelectedUID(list.pop().uid);
            //     }
            // } else {
            //     const list = productRows.filter(i => i.barcodes.indexOf(scan));
            //     if(list.length > 0) {
            //         setSelectedUID(list.pop().uid);
            //     }
            // }
        }
    }
    
    const items = productRows
        ?.map(i => ({...i, sortIndex: i.uid == selectedUID?100:0})) /* create sorting index */
        ?.sort((a,b) => b.sortIndex - a.sortIndex) /* sort by sorint index */
        ?.map((item) => (
            <Item 
                key={item.product_id}
                selected={selectedUID===item.uid}
                pickedQuantity={quantity}
                onPick={(qty) => setQuantity(qty)}
                {...item} 
            />
        )
    )

    const locations = locationRows?.map((item) => (<Chip key={item.location_id} label={item.location_name} />))
    locations.push(scannedBarcode)

    return (<React.Fragment>
        <Stack direction={'column'}>
            <BarcodeReader  minLength={5} onScan={onScan} />
            <div>
                {locations}
            </div>
            <List>
                { items }
            </List>
        </Stack>

    </React.Fragment>)
}